import axiosPost from "./axiosPost";

export const cargosUpd = async (credentials, data) => {
    const body = {
        service: "NQNCargoUpd",
        params: {
            nqnCargoId: data?.id,
            nqnCargoCod: data?.cod,
            nqnCargoTipo: data?.type,
            nqnCargoNombre: data?.name,
            nqnCargoHoras: data?.horas,
            nqnCargoVersion: data?.version,
            nqnCargoNivelModalidadId: data?.nivelModalidad === "Ninguno" ? null : data?.nivelModalidad,
            nqnCargoEsDirectivo: data?.esDirectivo ? "Y" : "N",
            nqnCargoVisible: data?.esVisible ? "Y" : "N",
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
