import axiosPost from "./axiosPost";

export const notificatorSend = async (email, template, valores) => {
    const body = {
        service: "NotificatorSend",
        params: {
            notificatorEmail: email,
            notificatorTemplateEmail: template,
            notificatorEmailSujeto: "Descarga la app de MIA",
            notificatorPlaceholderValores: [...valores],
        },
    };

    const result = await axiosPost(body);
    return result;
};
