import axiosPost from "./axiosPost";

export const distritosUpd = async (credentials, data) => {
    const body = {
        service: "NQNDistritoUpd",
        params: {
            nqnDistritoId: data.id,
            nqnDistritoNombre: data.name,
            nqnDistritoCod: data.cod,
            nqnDistritoVersion: data.version
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
