import React from "react";

import { useSetRecoilState } from "recoil";
import { modalData, secondaryModalData } from "recoilState/GlobalState";

import styles from "./HeaderButton.module.scss";

export default function HeaderButton({
    onClick,
    handleClick,
    text,
    icon,
    data,
    setOpen,
    placeholder,
    children,
    disabled = false,
    openModal = false
}) {
    const setModalDetails = useSetRecoilState(modalData);
    const setSecondaryModalDetails = useSetRecoilState(secondaryModalData);
    return (
        <div className={`${styles.tooltip} ${styles.bottom}`}>
            <button
                disabled={disabled}
                className={`${styles.buttons} ${disabled ? styles.disabled : ""}`}
                onClick={() => {
                    if (onClick) return onClick();
                    if (handleClick) return handleClick(data);
                    if (setOpen) setOpen(true);
                    if (data?.action !== "noOpen") {
                        if (data?.secondaryModal) {
                            setSecondaryModalDetails({
                                isOpen: true,
                                action: data?.action,
                                title: data?.title,
                            })
                        } else {
                            setModalDetails({
                                isOpen: true,
                                action: data?.action,
                                title: data?.title,
                                button: data?.button
                            });
                        }
                    }
                }}
            >
                {icon
                    ? <img src={icon} alt={text} />
                    : children
                }
                <span className={styles.tiptext}>{text}</span>
                <span className={styles.placeholder}>{placeholder}</span>
            </button>
        </div>
    );
}
