import React, { useEffect, useState } from 'react'
import XGrid from 'components/UI/Grid/XGrid'
import { movimientosDespachante as titles } from 'consts/titulos-de-tablas';
import styles from "styles/pages/Pages.module.scss"
import { RNESEntregaPanelGet } from "services/services-mia/RNESEntregaPanelGet.js"
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { v4 as uuidv4 } from "uuid";
import { Buttons, HeaderButton } from "components";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Box } from '@mui/material';
import Modal from "components/UI/Modal/Modal";
import Mapa from 'components/utils/mapa/Mapa';
import * as XLSX from 'xlsx';
import { CloudDownload } from '@mui/icons-material';

export default function Movimientos({ user, servicio, updateList }) {

    const credentials = useRecoilValue(userCredentials);
    const [rows, setRows] = useState([]);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });


    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };



    const getMovimientos = async () => {
        console.log("grid selected", user)
        const data = {
            RNESDespachanteId: user.usuarioId,
            RNESReceptorId: null,
        }
        const response = await RNESEntregaPanelGet(data, credentials);
        const mapResponse = response.result.map((mov) => {
            const latitud = Number(mov.RNESEntregaLatitud).toFixed(6);
            const longitud = Number(mov.RNESEntregaLlongitud).toFixed(6);
            return {
                id: uuidv4(),
                [titles[0]]: mov.RNESEntregaFecha,
                [titles[1]]: mov.RNESEntregaCantidad,
                [titles[2]]: mov.RNESArticuloNombre,
                [titles[3]]: `${mov.RNESContraparteNombreApellido} (${mov.RNESContraparteNroDeDocumentoX})`,
                [titles[4]]: mov.RNESEntregaUUID,
                [titles[5]]: `${latitud}, ${longitud}`,
                latitud: mov.RNESEntregaLatitud,
                longitud: mov.RNESEntregaLlongitud
            }
        });
        setRows(mapResponse);
    };
    useEffect(() => {
        getMovimientos();
    }, []);
    // Función para exportar a Excel

    const exportToExcel = () => {
        const filterFN = (e) => {
            return delete e.id && delete e.latitud && delete e.longitud
        }
        const filterRows = rows.filter((e) => filterFN(e))
        const worksheet = XLSX.utils.json_to_sheet(filterRows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'movimientos.xlsx');
    };

    return (

        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>

                {modalDetails.procede === "MAPS" && (
                    <Box minHeight={"60vh"} minWidth={"50vw"} height={"100%"} width={"calc(100% - 3em)"} >
                        <Box display={"flex"} justifyContent={"center"} width={"100%"} height={"100%"}>
                            <Mapa
                                lat={rowSelected.row.latitud}
                                lng={rowSelected.row.longitud}
                                width="100%"

                            />
                        </Box>
                    </Box>


                )}


            </Modal>
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Buttons filtersApplied={true}>
                    <HeaderButton
                        text={"Ver Mapa"}
                        handleClick={handleClick}
                        data={{
                            title: "Ubicacion de entrega",
                            procede: "MAPS"
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <FmdGoodIcon />
                    </HeaderButton>
                    <HeaderButton
                        text={"Descargar Excel"}
                        handleClick={exportToExcel}
                        disabled={rowSelected.row.id}>
                        <CloudDownload />
                    </HeaderButton>
                </Buttons>

            </Box>

            <div className={styles.container}>

                <XGrid
                    titles={titles}
                    rows={rows}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>


    )
}
