import React, { useEffect } from "react";

import { Box, Snackbar, Typography } from "@mui/material";

import { useRecoilState } from "recoil";
import { snackbarData } from "recoilState/GlobalState";

import CheckMark from "assets/icons/check-mark.svg";
import ErrorMark from "assets/icons/error-mark.svg";

import styles from "./Snackbar.module.scss";

export default function SnackBar() {
    const [{ message, severity, open }, setSnackbarInfo] = useRecoilState(snackbarData);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setSnackbarInfo({ open: false, message });
            }, 3500);
        }
    }, [message, open, setSnackbarInfo]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            className={`${styles.root} ${styles[severity]}`}
            color="white"
            key="top-center"
            open={open}
        >
            <Box
                component={"div"}
                alignItems={"center"}
                display={"flex"}
                gap={"1em"}
                justifyContent={"center"}
            >
                <img
                    src={severity === "success" ? CheckMark : ErrorMark}
                    alt="severity icon"
                />
                <Typography color={"white.main"}>{message}</Typography>
            </Box>
        </Snackbar>
    );
}
