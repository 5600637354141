import React from "react";
import { TextField, Switch, Box } from "@mui/material";
import { useField } from "formik";

export function FormikTextInput({
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    labelText,
    name,
    placeholder,
    defaultValue,
    type,
    ...props
}) {
    const [field, meta] = useField(name);

    return (
        <>
            <TextField
                className={className}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                id={id}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                InputLabelProps={{
                    ...InputLabelProps,
                    style: { fontSize: "1.3em" },
                    shrink: true,
                }}
                InputProps={{
                    ...InputProps,
                    autoComplete: "false",
                }}
                label={labelText}
                margin="dense"
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                type={type}
                variant="standard"
                {...field}
                {...props}
            />
        </>
    );
}

export function FormikSelectInput({
    children,
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    labelText,
    name,
    defaultValue,
    ...props
}) {
    const [field, meta] = useField(name);
    return (
        <>
            <TextField
                className={className}
                defaultValue={defaultValue}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                id={id}
                InputLabelProps={{
                    style: { fontSize: "1.3rem" },
                    shrink: true,
                }}
                label={labelText}
                margin="dense"
                name={name}
                select
                SelectProps={{ native: true }}
                variant="standard"
                {...field}
                {...props}
            >
                {children}
            </TextField>
        </>
    );
}


export const FormikYesOrNoInput = ({ labelText, name }) => {
    const [field, meta] = useField(name);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
            <p style={{ marginBottom: "5px", color: "rgba(0, 0, 0, 0.8)", fontWeight: "600" }}>{labelText}</p>
            <Box display={"flex"}>
                <p style={{ margin: "5px", marginLeft: "0" }}>No</p>
                <Switch name="name"{...field}
                    checked={field.value === true}
                    value={field.value}
                    color="secondary"
                />
                <p style={{ margin: "5px" }}>Si</p>
            </Box>
        </div>
    );
}

export function FormikDateInput({
    className,
    disabled,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    labelText,
    name,
    placeholder,
    defaultValue,
    type,
    ...props
}) {
    const [field, meta] = useField(name);

    return (
        <>
            <TextField
                type="date"
                className={className}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                fullWidth={fullWidth}
                id={id}
                helperText={
                    meta.error && meta.touched ? meta.error : helperText
                }
                InputLabelProps={{
                    ...InputLabelProps,
                    style: { fontSize: "1.3em" },
                    shrink: true,
                }}
                InputProps={{
                    ...InputProps,
                    autoComplete: "false",
                }}
                label={labelText}
                margin="dense"
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                variant="standard"
                {...field}
                {...props}
            />
        </>
    );
}
