import axiosPost from "./axiosPost";

export const RNESEntregaPanelGet = async (data, credentials) => {
    const { RNESDespachanteId, RNESReceptorId } = data;
    const body = {
        service: "RNESEntregaPanelGet",
        params: {
            RNESDespachanteId,
            RNESReceptorId,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
