import axiosPost from "./axiosPost";

export const getFeriados = async (credentials, nqnFeriadoId) => {
    const body = {
        service: "NQNFeriadoGet",
        params: {
            nqnFeriadoId
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
