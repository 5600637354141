export const INSIDE_DRAWER = {
    admin: {
        service_name: "Administración",
        show_drawer: true,
        isOpen: false,
        route: "administracion?servicio=",
    },
    config: {
        service_name: "Configuración",
        show_drawer: true,
        isOpen: false,
        route: "configuracion?servicio=",
    },
    users: {
        service_name: "Usuarios",
        show_drawer: true,
        isOpen: false,
        route: "usuarios",
    },
};

export const Routes = {
    login: {
        route: "/login",
    },
    feriados:{
        route: "/feriados",
        wordKey: "Configuración > Feriados",
    },
    feriadosSerie:{
        route: "/feriados-serie",
        wordKey: "Configuración > Serie de Feriados",
    },
    calendarios:{
        route: "/calendarios",
        wordKey: "Configuración > Calendarios",
    },
    cicloLectivo:{
        route: "/ciclo-lectivo",
        wordKey: "Configuración > Ciclos lectivos",
    },
    distritos:{
        route: "/distritos",
        wordKey: "Configuración > Distritos Escolares",
    },
    escuelas:{
        route: "/escuelas",
        wordKey: "Configuración > Escuelas",
    },
    cargos:{
        route: "/cargos",
        wordKey: "Configuración > Cargos",
    },
    docentes:{
        route: "/docentes",
        wordKey: "Configuración > Docentes",
    },
    niveles:{
        route: "/niveles",
        wordKey: "Configuración > Niveles",
    },
    eventos:{
        route: "/eventos",
        wordKey: "Eventos",
    },
    usuarios:{
        route: "/usuarios",
        wordKey: "Usuarios",
    },
    dashboard: {
        route: "/",
        wordKey: "Home",
        showMenuItem: true,
        needSuperAuth: false,
    },

    usuarios: {
        route: "/usuario",
        wordKey: "Usuarios",
        showMenuItem: true,
        needSuperAuth: false,
    },

    administracion: {
        route: "/administracion",
        wordKey: "Administracion de Usuarios",
        showMenuItem: false,
        needSuperAuth: false,
    },
    dashboardDatosPersonales: {
        route: "/administracion/dashboard-usuarios",
        wordKey: "Datos Personales",
        showMenuItem: false,
        needSuperAuth: false,
    },

    usuariosXServicio: {
        route: "/usuarios-x-servicio",
        wordKey: "Usuarios x servicio",
        showMenuItem: false,
        needSuperAuth: false,
    },
};

export const servicesRoutes = {
    RNCALOR: [
        {
            route: "/beneficiarios",
            wordKey: "Beneficiarios",
            isGroup: false,
        },
        {
            route: "/despachantes",
            wordKey: "Despachantes",
            isGroup: false,
        },

        {
            route: "/usuarios",
            wordKey: "Usuarios",
            isGroup: false,
        },
    ],
    RNES: [
        {
            route: "/beneficiarios",
            wordKey: "Beneficiarios",
            isGroup: false,
        },
        {
            route: "/despachantes",
            wordKey: "Despachantes",
            isGroup: false,
        },
        {
            route: "/informes",
            wordKey: "Informes",
            isGroup: false,
        },

        {
            route: "/usuarios",
            wordKey: "Usuarios",
            isGroup: false,
        },
    ],
};
