import React, { useState, useEffect, useCallback } from "react";
import XGridServer from "components/UI/Grid/XGridServer";
import { useFormik } from "formik";
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, MovimientosModal } from "./Actions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { RNBeneficiarioGet } from "services/services-mia/RNBeneficiarioGet";
import { HeaderButton } from "components";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import TimelineIcon from '@mui/icons-material/Timeline';
import { Buttons } from "components";
import Filters from "components/Filters";
import { ACTIONS } from "consts/Actions";
import moment from "moment";
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";
import styles from "styles/pages/Pages.module.scss";
import { useService } from "components/route/ServiceRoute";
import DetailModal from "./Actions/DetailModal";
import { connectionRolGet, rolGet } from "services/services-mia";


const onboardStatus = [
    {
        onboardStatusCod: '',
        onboardStatusNombre: 'Todos'
    },
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];
const genderName = {
    "M": "Masculino",
    "F": "Femenino",
    "X": "No binario"
}

export default function BeneficiariosEntregaSociales() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const [editable, setEditable] = useState(false);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [roles, setRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [resetTable, setResetTable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const { service } = useService();
    console.log("credentials", service)
    const formik = useFormik({
        initialValues: {
            usuarioApellidos: "",
            usuarioNumeroDeDocumentoX: "",
            rolCod: "todos",
            merchantServicioUsuarioStatus: "",
            fechaDesde: moment().date(),
            fechaHasta: moment().date()
        },
        onSubmit: () => {
            setResetTable((value) => !value);
        },
    })

    const getBeneficiarios = async (page, pageSize) => {
        const data = {
            rolCod: null,
            servicioHooliCod: service.servicioCod,
            page: page + 1,
            pageSize,
            usuarioTieneApp: null,
            usuarioNroDeDocumentoX: formik.values.usuarioNumeroDeDocumentoX || null,
            usuarioApellidos: formik.values.usuarioApellidos || null,
            merchantServicioUsuarioStatus: formik.values.merchantServicioUsuarioStatus || null,
            fechaDesde: null,
            fechaHasta: null,
            fechaTipo: null,
        }
        const { result, total } = await RNBeneficiarioGet(credentials, data);
        const newRows = await result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                nombreServicio: usuario.servicioNombre,
                servicioId: usuario.servicioId,
                estadoCod: usuario.relacionStatusCod,
                email: usuario.usuarioMail,
                rolId: usuario.rolId,
                rolCod: usuario.rolCod,
                rolNombre: usuario.rolNombre,
                [titles[0].field]: usuario.usuarioNombreCompleto,
                [titles[1].field]: usuario.usuarioNroDeDocumentoX,
                [titles[2].field]: genderName[usuario.usuarioSexo],
                [titles[3].field]: usuario.usuarioFechaDeNacimiento,
                [titles[4].field]: usuario.usuarioMail,

                [titles[6].field]: usuario.usuarioId === null ? 'NO' : 'SI',
                [titles[7].field]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
                [titles[8].field]: usuario.relacionStatus,
                [titles[9].field]: usuario.usuarioFecha,
            };
        });

        return {
            rows: newRows,
            rowCount: total
        }

    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const updateList = useCallback(() => {
        setRowSelected({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };



    useEffect(() => {
        setRoute({
            wordKey: `Beneficiarios - ${service.servicioNombre}`
        })
    }, [service.servicioNombre, setRoute])
    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                let rolesFilter = null;
                const resultRoles = await rolGet(service.servicioCod, credentials);
                rolesFilter = resultRoles.result.filter((e) => e.rolCod === "BEN" || e.rolCod === "BDE");
                const connectionRoles = await connectionRolGet(service.servicioCod, credentials)
                setConnectionRoles(connectionRoles.result);
                setRoles([{ rolCod: 'Todos', rolNombre: 'Todos' }, ...rolesFilter]);
            })();
        }
    }, [credentials, service])

    useEffect(() => {
        if (rowSelected.row.id && service.servicioPermiso === 'E' && connectionRoles.some(rol => rol.rolCod === rowSelected.row.rolCod)) {
            setEditable(true);
            return;
        }
        setEditable(false);
    }, [connectionRoles, rowSelected.row.id, rowSelected.row.rolCod, service])

    return (
        <main className={styles.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <MovimientosModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        usuarioId={rowSelected.row.usuarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={styles["filters-actions"]}>
                <Filters
                    formik={formik}
                    inputsRender={
                        [
                            { type: "text", name: "usuarioApellidos", label: "Apellido", disabled: false },
                            { type: "text", name: "usuarioNumeroDeDocumentoX", label: "Documento", disabled: false },
                            { type: "select", name: "merchantServicioUsuarioStatus", label: "Onboard", disabled: false, options: onboardStatus, firtsOptionKey: "onboardStatusCod", firstOptionName: "onboardStatusNombre", firstOptionValue: "onboardStatusCod" },
                            // { type: "select", name: "estado", label: "Estado", disabled: false, options: serviceStatus, firtsOptionKey: "servicioStatusCod", firstOptionName: "servicioStatusNombre", firstOptionValue: "servicioStatusCod" }
                        ]
                    }
                />
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle del Usuario",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id || !rowSelected.row.usuarioId}
                    >
                        <VisibilityOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear relación"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear conexión",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || service.servicioPermiso !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar relación"}
                        handleClick={handleClick}
                        data={{
                            title: "Actualizar conexión",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!editable}
                    >
                        <EditOutlined />
                    </HeaderButton>

                    <HeaderButton
                        text={"Movimientos"}
                        handleClick={handleClick}
                        data={{
                            title: `Beneficiario - Movimientos: ${rowSelected.row.fullName}`,
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <TimelineIcon />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={styles.container}>
                <XGridServer
                    titles={titles.filter((e) => e.field !== "rol")}
                    updateList={getBeneficiarios}
                    forceUpload={forceUpload}
                    forceReset={resetTable}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}