import axiosPost from "../axiosPost";

export const merchantLogoGet = async ({
    merchantId
}, credentials) => {
    const body = {
        service: "MerchantLogoGet",
        params: {
            merchantId: credentials.merchantId || merchantId,
        },
    };

    return await axiosPost(body, credentials);
};
