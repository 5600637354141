import axiosPost from "./axiosPost";

export const RNConnectionUpd = async (data, credentials) => {
    const {
        merchantServicioUsuarioId,
        rolCod,
        merchantServicioUsuarioStatus,
        conexionUUID,
        merchantServicioUsuarioMail,
    } = data;
    const body = {
        service: "RNConnectionUpd",
        params: {
            merchantServicioUsuarioId,
            rolCod,
            merchantServicioUsuarioStatus,
            merchantServicioUsuarioMail,
            conexionUUID,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
