import axiosPost from "./axiosPost";

export const escuelasUpd = async (credentials, data) => {
    const body = {
        service: "NQNEscuelaUpd",
        params: {
            nqnEscuelaId: data.escuelaId,
            nqnEscuelaCUE: data.cue,
            nqnEscuelaNombre: data.name,
            nqnEscuelaCalle: data.calle,
            nqnEscuelaCasa: data.casa,
            localidadId: data.localidadId,
            nqnEscuelaCPA: data.cpa,
            nqnEscuelaLatitud: data.lat.toFixed(6),
            nqnEscuelaLongitud: data.lng.toFixed(6),
            nqnEscuelaVersion: data.version,
            nqnCalendarioId: data.calendario,
            nqnDistritoId: data.distritoId,
            nqnNivelModalidadId : Number(data.nivelModalidadId),
            nqnEscuelaTolerancia: Number(data.tolerancia)
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
