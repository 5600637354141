import React from 'react';
import { Modal, Box } from '@mui/material';
// import exitImage from "../../../assets/icons/exit-image.png";
// import ButtonGradient from '../../utils/button/ButtonGradient';
import styles from "./index.module.scss"
import { Button } from 'components';
function ModalError({ isOpen, setIsOpen, handleClick, title, text, oneButton = false }) {

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpen}
        >

            <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box width={"500px"} height={"350px"} bgcolor={"#EEEEEE"} borderRadius={"28.98px"} display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"space-evenly"}>
                    {/* <Box marginBottom={"20px"}>
                        <img src={""} alt='hooli' width={"120px"} />
                    </Box> */}
                    <Box className={styles.containerTitle}>
                        <h1>{title || "Texto prueba"}</h1>
                        <p>{text || "Texto prueba"}</p>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} gap={2} className={styles.containerBtn}>
                        <Button text={"Aceptar"} size={"medium"} onClick={handleClick} />
                        {/* {!oneButton && <span style={{ fontSize: "18px" }} onClick={() => setIsOpen(false)} >Cerrar</span>} */}
                    </Box>
                </Box>
            </Box>


        </Modal>
    )
}

export default ModalError