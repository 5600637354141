import React, { useEffect, useRef, useState, version } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikDateInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { escuelasGet } from "services/services-mia/escuelasGet";
import { Box, Grid } from "@mui/material";
import { docentesGet } from "services/services-mia/docentesGet";
import { cargosGet } from "services/services-mia/cargosGet";
import { docentesUpd } from "services/services-mia/docentesUpd";
import { docenteCalendarioIns } from "services/services-mia/docentesCalendarioIns";
import { InfoCard } from "components/InfoCard/InfoCard";
import { docentesDel } from "services/services-mia/docentesDel";

export default function DeleteModal({ updateList, user, servicio, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [cargos, setCargos] = useState([]);
    const [escuelas, setEscuelas] = useState([]);
    const [hr, setHr] = useState(false);

    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getData = async () => {
        setIsLoading(true);
        const result = await docentesGet(credentials, { id: user.id })
        console.log(result)
        formikRef.current.setFieldValue("userId", result.result[0].nqnDocenteId);
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        // console.log(values)
        const response = await docentesDel(credentials, { id: values.userId })
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Docente modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                userId: ""
            }}
            // validationSchema={
            //     yup.object({
            //         date: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
            //     })
            // }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <InfoCard nombre={user.name} apellido={user.lastname} escuela={user.escuelaNombre} cargo={user.cargoNombre} />
                <div className={styles["column-sm"]}>
                    {step.basico && <Grid width={"100%"}>
                        {/* <Box display={"flex"} width={"100%"} gap={4}>
                        <FormikTextInput
                                fullWidth={true}
                                name="horas"
                                labelText="Nombre"
                                disabled={hr !== "C"}
                            />
                        </Box> */}
                        {/* <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikDateInput
                                data={dataFormik}
                                formik={formikRef.current}
                                fullWidth={true}
                                name="date"
                                labelText="Fecha"
                                handleChange={(e) => {
                                    formikRef.current.setFieldValue("date", e.target.value);
                                }}
                            />
                        </Box> */}
                    </Grid>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Borrar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
