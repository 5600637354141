import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { cargosGet } from "services/services-mia/cargosGet";
import { cargosDel } from "services/services-mia/cargosDel";

export default function DeleteModal({ user, updateList, servicio, closeModal, setterRowSelected }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getData = async () => {
        setIsLoading(true);
        const result = await cargosGet(credentials, { id: user.id })
        formikRef.current.setValues({
            cod: result[0].nqnCargoCod,
            name: result[0].nqnCargoNombre,
        });
        setData(result[0]);
        setIsLoading(false);
    }

    const handleDeleteCargo = async (values) => {
        const response = await cargosDel(
            credentials,
            { id: data.nqnCargoId }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Cargo eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                cod: "",
                name: "",
            }}
            validationSchema={
                yup.object({
                    cod: yup.string().required("El código es requerido"),
                    name: yup.string().required("El nombre es requerido"),
                })
            }
            onSubmit={(values) => {
                handleDeleteCargo(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="name"
                            labelText="Nombre"
                            disabled
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                            disabled
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
