import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import { theme } from "./styles/theme/theme";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <RecoilRoot>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </RecoilRoot>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
