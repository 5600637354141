import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikDateInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { getFeriados } from "services/services-mia";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { SerieFeriadoIns } from "services/services-mia/serieFeriadoIns";
import { calendarioGet } from "services/services-mia/calendarioGet";
import { cicloIns } from "services/services-mia/cicloIns";

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [data, setData] = useState([]);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getCiclosData = async () => {
        setIsLoading(true);
        const result = await calendarioGet(credentials)
        setData(result);
        setIsLoading(false);
    }

    const handleCreateCiclo = async (values) => {
        const year = values.fechaDesde.split("-")
        if (Number(year[0]) !== Number(values.año)) {
            setSnackBarInfo({
                message: "El año no coincide con la fecha de inicio",
                severity: "warning",
                open: true,
            });
        } else {
            const response = await cicloIns(
                credentials,
                values
            );
            if (response.length === 0) {
                setSnackBarInfo({
                    message: "Ciclo lectivo creado correctamente",
                    severity: "success",
                    open: true,
                });
                formikRef.current.resetForm();
                updateList();
                closeModal();
            }
        }
    }
    useEffect(() => {
        getCiclosData()
    }, []);
    const dataFormik = {
        date: { label: "Fecha" },
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                año: "",
                calendarioId: "",
                fechaDesde: "",
                fechaHasta: "",
                tramo: ""
            }}
            validationSchema={
                yup.object({
                    año: yup.string("Debe ingresar un año").required("Debe ingresar un año"),
                    calendarioId: yup.string("Debe seleccionar un calendario").required("Debe seleccionar un calendario"),
                    fechaDesde: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                    fechaHasta: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                    tramo: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre")
                })
            }
            onSubmit={(values) => {
                handleCreateCiclo(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="calendarioId"
                            labelText="Calendario"
                        >
                            <option value={""} selected disabled >
                                Seleccione
                            </option>
                            {data.map(({ nqnCalendarioId, nqnCalendarioNombre }) => (
                                <option
                                    key={nqnCalendarioId}
                                    data-id={nqnCalendarioId}
                                    value={nqnCalendarioId}
                                >
                                    {nqnCalendarioNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="tramo"
                            labelText="Tramo"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="año"
                            labelText="Año"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fechaDesde"
                            labelText="Fecha Desde"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fechaDesde", e.target.value);
                            }}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fechaHasta"
                            labelText="Fecha hasta"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fechaHasta", e.target.value);
                            }}
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
