import axiosPost from "./axiosPost";

export const RNUsuarioGet = async (credentials, data) => {
    const {
        rolCod,
        servicioHooliCod,
        page,
        pageSize,
        usuarioTieneApp,
        usuarioNroDeDocumentoX,
        usuarioApellidos,
        merchantServicioUsuarioStatus,
        fechaDesde,
        fechaHasta,
        fechaTipo,
    } = data;
    const body = {
        service: "RNUsuarioGet",
        params: {
            rolCod,
            servicioHooliCod,
            page,
            pageSize,
            usuarioTieneApp,
            usuarioNroDeDocumentoX,
            usuarioApellidos,
            merchantServicioUsuarioStatus,
            fechaDesde,
            fechaHasta,
            fechaTipo,
        },
    };

    const result = await axiosPost(body, credentials);

    if (page !== null && pageSize !== null) {
        return {
            result: result.result,
            total: result.status.id,
            status: result.status,
        };
    }
    return result.result;
};
