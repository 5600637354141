import axiosPost from "./axiosPost";

export const escuelasStatusGet = async (credentials, data) => {
    const body = {
        service: "NQNEscuelaStatusGet",
        params: {
            nqnEscuelaId: data?.id,
        },
    };
    const result = await axiosPost(body, credentials);
    return result.result;
};
