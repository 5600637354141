import axiosPost from "./axiosPost";

export const distritosIns = async (credentials, data) => {
    const body = {
        service: "NQNDistritoIns",
        params: {
            nqnDistritoNombre: data.name,
            nqnDistritoCod: data.cod, 
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
