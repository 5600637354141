import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation, useNavigate } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo, userCredentials, rioNegroServices, snackbarData, userServices } from "./recoilState/GlobalState";

import { getUserCredentials } from "services/getUserCredentials";
import { checkAuth } from "services/checkAuth";
import { usuarioMinimosGet } from "services/services-mia";

import ProtectedRoute from "./components/route/ProtectedRoute";
import { CircularIndeterminate, Header } from "components";
import SnackBar from "components/utils/snackbars/Snackbar";

import {
    Dashboard,
    Usuarios,
    Login,
    UsuariosXServicio,
    UserDetailsDashboard,
    Feriados,
    Distritos,
    FeriadosSerie,
    Escuelas,
    Cargos,
    Docentes,
    Nivel,
    Eventos,
    Calendario,
    CicloLectivo
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import ServiceRoute from "components/route/ServiceRoute";
import { merchantLogoGet } from "services/services-mia/merchant";
// import { RNServicioGet } from "services/services-mia/RNServicioGet";

import s from "./App.module.scss"
import DespachantesProgramaCalor from "pages/programa-calor/despachantes/index";
import BeneficiariosProgramaCalor from "pages/programa-calor/beneficiarios";
import BeneficiariosEntregaSociales from "pages/entregas-sociales/beneficiarios";
import DespachantesEntregaSociales from "pages/entregas-sociales/despachantes";
import InformesAsisteRN from "pages/entregas-sociales/informes";
import ModalError from "components/modalError/ModalError";
import { subscribe, unsubscribe } from "./utils/eventManager.js";
import { permisosCheck } from "services/services-mia/permisosCheck";

export default function App() {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [permisos, setPermisos] = useRecoilState(userServices);
    const [routeError, setRouteError] = useState(false);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const setInfo = useSetRecoilState(userInfo);
    // const setServices = useSetRecoilState(rioNegroServices);
    const navigate = useNavigate();

    const location = useLocation();

    const handleClickModal = () => {
        setIsOpen(false);
        if (routeError) {
            navigate(routeError)
        }
    }

    useEffect(() => {
        setLoading(true);
        (async () => {
            const newCredentials = await getUserCredentials();
            if (newCredentials) {
                setCredentials(newCredentials);
                let permisos = await permisosCheck(newCredentials)
                setPermisos({
                    rol: permisos[0].rol.nivel || "NM",
                    cargos: permisos[0].rol.permisos.cargos || "N",
                    distritos: permisos[0].rol.permisos.distritos || "N",
                    docentes: permisos[0].rol.permisos.docentes || "N",
                    calendarios: permisos[0].rol.permisos.calendarios || "N",
                    escuelas: permisos[0].rol.permisos.escuelas || "N",
                    escuelasStatus: permisos[0].rol.permisos.escuelasStatus || "N",
                    eventos: permisos[0].rol.permisos.presentismo || "N",
                    feriados: permisos[0].rol.permisos.feriados || "N",
                    nivel: permisos[0].rol.permisos.niveles || "N",
                    usuarios: permisos[0].rol.permisos.usuarios || "N",
                })
                const userData = await usuarioMinimosGet(newCredentials);
                const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                setInfo({ ...merchantData[0], ...userData });
                // const { result: servicesData } = await RNServicioGet({}, newCredentials);
                // setServices([...servicesData]);
            }
            setIsAuth(checkAuth());
            setLoading(false);
        })();
    }, [setCredentials, setInfo]);

    useEffect(() => {
        if (loading) return;
        setIsAuth(checkAuth());
    }, [credentials, loading]);
    console.log(permisos)
    useEffect(() => {
        const handleErrorServer = (data) => {
            setSnackBarInfo({
                message: data.message,
                severity: "warning",
                open: true,
            });
        };
        const handleSessionExpired = (data) => {
            setRouteError(ROUTES.login.route);
            setIsOpen(true);
        };
        subscribe("serverError", handleErrorServer);
        subscribe("sessionExpired", handleSessionExpired);
        return () => {
            unsubscribe("serverError", handleErrorServer);
            unsubscribe("sessionExpired", handleSessionExpired);
        };
    }, []);
    if (loading) return <CircularIndeterminate />;

    return (
        <div className={s.container}>
            <div className={setIsAuth.header}>
                {location.pathname !== ROUTES.login.route && <Header />}

            </div>
            <div className={s.body}>
                <SnackBar />
                <ModalError isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title={"Ups..."} text={"Su sesión ha expirado"} />
                <Switch>
                    <Route path={ROUTES.login.route} exact element={<Login />} />

                    <Route
                        element={
                            <ProtectedRoute
                                redirectProtectedRoute={ROUTES.login.route}
                                isAuth={isAuth}
                            />
                        }
                    >
                        <Route
                            path={ROUTES.dashboard.route}
                            exact
                            element={<Dashboard route={ROUTES.dashboard} />}
                        />
                        <Route
                            path={ROUTES.feriados.route}
                            exact
                            element={<Feriados route={ROUTES.feriados} />}
                        />
                        <Route
                            path={ROUTES.feriadosSerie.route}
                            exact
                            element={<FeriadosSerie route={ROUTES.feriadosSerie} />}
                        />
                        <Route
                            path={ROUTES.calendarios.route}
                            exact
                            element={<Calendario route={ROUTES.calendarios} />}
                        />
                        <Route
                            path={ROUTES.cicloLectivo.route}
                            exact
                            element={<CicloLectivo route={ROUTES.cicloLectivo} />}
                        />
                        <Route
                            path={ROUTES.distritos.route}
                            exact
                            element={<Distritos route={ROUTES.distritos} />}
                        />
                        <Route
                            path={ROUTES.escuelas.route}
                            exact
                            element={<Escuelas route={ROUTES.escuelas} />}
                        />
                        <Route
                            path={ROUTES.cargos.route}
                            exact
                            element={<Cargos route={ROUTES.cargos} />}
                        />
                        <Route
                            path={ROUTES.docentes.route}
                            exact
                            element={<Docentes route={ROUTES.docentes} />}
                        />
                        <Route
                            path={ROUTES.eventos.route}
                            exact
                            element={<Eventos route={ROUTES.eventos} />}
                        />
                        <Route
                            path={ROUTES.niveles.route}
                            exact
                            element={<Nivel route={ROUTES.niveles} />}
                        />
                        <Route
                            path={ROUTES.usuarios.route}
                            exact
                            element={<Usuarios route={ROUTES.usuarios} />}
                        />
                        <Route
                            path={"usuarios/:usuarioId/details"}
                            element={<UserDetailsDashboard />}
                        />

                        <Route
                            path="servicio/RNCALOR"
                            element={<ServiceRoute servicioCod="RNCALOR" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route path="beneficiarios" element={<BeneficiariosProgramaCalor />} />
                            <Route path="despachantes" element={<DespachantesProgramaCalor />} />
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />

                        </Route>
                        <Route
                            path="servicio/RNES"
                            element={<ServiceRoute servicioCod="RNES" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route path="beneficiarios" element={<BeneficiariosEntregaSociales />} />
                            <Route path="despachantes" element={<DespachantesEntregaSociales />} />
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />
                            <Route
                                path="informes"
                                element={<InformesAsisteRN />}
                            />

                        </Route>

                        <Route
                            path="/*"
                            element={<Dashboard />}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
