import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials, userServices } from 'recoilState/GlobalState';
import { Button } from "components";
import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, DeleteModal } from "./Actions";
import { Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, EditOutlined, DeleteOutlineRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import { useFormik } from 'formik';
import Filters from 'components/Filters';
import { ciclosLectivosGrid as titles } from "consts/titulos-de-tablas";
import moment from 'moment';
import XGrid from 'components/UI/Grid/XGrid';
import { cicloGet } from 'services/services-mia/cicloGet';
import { calendarioGet } from 'services/services-mia/calendarioGet';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

export default function CicloLectivo() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [ciclo, setCiclos] = useState([])
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [filter, setFilter] = useState([]);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const service = useService()
    const permisos = useRecoilValue(userServices);
    const credentials = useRecoilValue(userCredentials);

    const formik = useFormik({
        initialValues: {
            calendarioId: ""
        },
        onSubmit: (values) => {
            getCicloData()
        },
    });

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const getCicloData = async (reset) => {
        setCiclos([])
        setPage(0)
        setRowSelected({ status: false, row: {} })
        setIsLoading(true)
        const result = await cicloGet(credentials, { calendarioId: reset ? "" : formik.values.calendarioId })
        const filtros = await calendarioGet(credentials)
        setFilter([{ nqnCalendarioId: "", nqnCalendarioNombre: "Todos" }, ...filtros])
        const newRows = result[0]?.nqnCalendarioCicloId ? result.map((item) => {
            return {
                id: item.nqnCalendarioCicloId,
                name: item.nqnCalendarioNombre,
                tramo: item.nqnCalendarioCicloTramo,
                fechaDesde: item.nqnCalendarioCicloFechaDesde ? moment(item.nqnCalendarioCicloFechaDesde).format("DD/MM/YYYY").toString() : null,
                fechaHasta: item.nqnCalendarioCicloFechaHasta ? moment(item.nqnCalendarioCicloFechaHasta).format("DD/MM/YYYY").toString() : null,
                año: item.nqnCalendarioCicloAnioLectivo,
                version: item.nqnFeriadoVersion,
            };
        }) : [];
        setCiclos(newRows)
        setIsLoading(false)
        return {
            rows: newRows,
            rowCount: newRows.length,
        };
    }
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const resetForm = () => {
        formik.setFieldValue("calendarioId", "");
        getCicloData(true)
    };
    useEffect(() => {
        if (modalDetails.isOpen === false) {
            getCicloData()
        }
    }, [service, updateList, modalDetails.isOpen])
    // console.log(ciclo)
    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "calendarioId", label: "Calendario", options: filter, firtsOptionKey: "nqnCalendarioId", firstOptionName: "nqnCalendarioNombre", firstOptionValue: "nqnCalendarioId" },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={isLoading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => getCicloData()}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Ciclo lectivo",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id || permisos.calendarios === "L"}
                    >
                        <DeleteOutlineRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear Ciclo lectivo",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || permisos.calendarios === "L"}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Ciclo lectivo",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || permisos.calendarios === "L"}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGrid
                    rows={ciclo}
                    titles={titles}
                    loading={isLoading}
                    updateList={getCicloData}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </main>
    )
}
