export const checkCredentials = () => {
    if (
        sessionStorage.getItem("hooliToken") &&
        sessionStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: sessionStorage.getItem("hooliToken"),
            sessionId: sessionStorage.getItem("sessionId"),
            merchantId: sessionStorage.getItem("merchantId"),
            merchantRZ: sessionStorage.getItem("merchantRZ"),
            merchantNombre: sessionStorage.getItem("merchantNombre"),
            merchantCUIT: sessionStorage.getItem("merchantCUIT"),
        };
    }
    return null;
};

export const checkAuth = () => {
    return (
        sessionStorage.getItem("hooliToken") &&
        sessionStorage.getItem("sessionId")
    ) ? true : false;
};
