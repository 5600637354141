import axiosPost from "../axiosPost";

export const loginQRGet = async ({
    sesionLatitud,
    sesionLongitud
}) => {
    const body = {
        service: "LoginQRGet",
        params: {
            sesionLatitud,
            sesionLongitud,
        },
    };

    return await axiosPost(body);
};
