import React from "react";
import { Button as ButtonMUI } from "@mui/material";
import classes from "./Button.module.scss";

export default function Button({
    children,
    text,
    className = "",
    ...props
}) {
    return (
        <ButtonMUI {...props} className={`${classes.button} ${className}`}>
            {text || children}
        </ButtonMUI>
    );
}