import axiosPost from "./axiosPost";

export const cicloUpd = async (credentials, data) => {
    const body = {
        service: "NQNCalendarioCicloUpd",
        params: {
            nqnCalendarioCicloId: data?.cicloId,
            nqnCalendarioCicloAnioLectivo: data?.año,
            nqnCalendarioCicloFechaDesde: data?.fechaDesde,
            nqnCalendarioCicloFechaHasta: data?.fechaHasta,
            nqnCalendarioCicloTramo: data?.tramo, 
            nqnCalendarioCicloVersion: data?.version
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
