import React from "react";
// styles
import styles from "./Buttons.module.scss";

export default function Buttons({ children, filtersApplied = false }) {
    return (
        <div className={styles.buttonsContainer}>
            <div className={filtersApplied ? styles.icons : styles.iconsNoFilters}>
                {children}
            </div>
        </div>
    );
}
