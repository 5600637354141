import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikDateInput, FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { Box, Grid } from "@mui/material";
import { escuelasGet } from "services/services-mia/escuelasGet";
import { cargosGet } from "services/services-mia/cargosGet";
import { docentesIns } from "services/services-mia/docentesIns";

const sexo = [
    { value: "M", name: "Masculino", id: "1" },
    { value: "F", name: "Femenino", id: "2" },
    { value: "X", name: "No binario", id: "3" }
];

const cantidad = [
    { value: "1", name: "1", id: "1" },
    { value: "2", name: "2", id: "2" },
    { value: "3", name: "3", id: "3" }
]

export default function InsertModal({ updateList, roles, servicio, closeModal, latitud, longitud }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [cargos, setCargos] = useState([]);
    const [escuelas, setEscuelas] = useState([]);
    const [hr, setHr] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cargoEsc, setCargoEsc] = useState([]);
    const [cargosDisabled, setCargosDisabled] = useState(false);
    const [cargosFilter, setCargosFilter] = useState([]);

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };

    const [step, setStep] = useState(1);

    const getData = async () => {
        setIsLoading(true);
        const esc = await escuelasGet(credentials)
        const carg = await cargosGet(credentials)
        setCargos(carg)
        setCargosFilter(carg)
        setEscuelas(esc);
        setIsLoading(false);
    }
    const handleCreateDocente = async (values) => {
        const response = await docentesIns(
            credentials,
            values
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Docente añadido correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
            setStep(1);
        }
    }
    useEffect(() => {
        let filterCargo = cargos.filter(c => c.nqnCargoNivelModalidadId === cargoEsc)
        setCargosFilter(filterCargo)
    }, [cargoEsc]);
    useEffect(() => {
        getData()
        return () => {
            setStep(1);
        }
    }, []);
    const dataFormik = {
        date: { label: "Fecha" },
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                tipoDoc: 1,
                doc: "",
                sexo: "",
                escuelaId: "",
                cargosCant: 1,
                cargoId: "",
                horas: 0,
                horasLu: "",
                horasMa: "",
                horasMi: "",
                horasJu: "",
                horasVi: "",
                tipoHr: "",
                date: "",
            }}
            validationSchema={
                yup.object({
                    doc: yup.string("Debe ingresar un documento").required("Debe ingresar un documento"),
                    sexo: yup.string("Debe ingresar un sexo").required("Debe ingresar un sexo"),
                    escuelaId: yup.string("Debe ingresar una escuela").required("Debe ingresar una escuela"),
                    cargosCant: yup.string("Debe ingresar cantidad de cargos").required("Debe ingresar cantidad de cargos"),
                    cargoId: yup.string("Debe ingresar un cargo").required("Debe ingresar un cargo"),
                    horas: yup.number().required("Las horas son obligatorias").min(1, "Las horas tienen que ser mayores a 0"),
                    date: yup.date().required('La fecha es obligatoria'),
                })
            }
            onSubmit={(values) => {
                handleCreateDocente(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputsMd} noValidate>
                <div className={styles["column-sm"]}>
                    <Grid width={"100%"}>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikTextInput
                                fullWidth={true}
                                name="doc"
                                labelText="Documento"
                            />
                            <FormikSelectInput
                                fullWidth={true}
                                name="sexo"
                                labelText="Genero"
                                onChange={(e) => {
                                    formikRef.current.setFieldValue("sexo", e.target.value);
                                }}
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {sexo.map(({ value, name }) => (
                                    <option
                                        key={value}
                                        data-id={value}
                                        value={value}
                                    >
                                        {name}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Box>
                        <FormikSelectInput
                            fullWidth={true}
                            name="escuelaId"
                            labelText="Escuela"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("escuelaId", e.target.value);
                                const esc = escuelas.find((i) => i.nqnEscuelaId === Number(e.target.value))
                                setCargoEsc(esc.nqnNivelModalidadId)
                            }}
                        >
                            <option value={""} selected disabled >
                                Seleccione
                            </option>
                            {escuelas.map(({ nqnEscuelaId, nqnEscuelaNombre }) => (
                                <option
                                    key={nqnEscuelaId}
                                    data-id={nqnEscuelaId}
                                    value={nqnEscuelaId}
                                >
                                    {nqnEscuelaNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikDateInput
                                data={dataFormik}
                                formik={formikRef.current}
                                fullWidth={true}
                                name="date"
                                labelText="Fecha de alta"
                                handleChange={(e) => {
                                    formikRef.current.setFieldValue("date", e.target.value);
                                }}
                            />
                            <FormikSelectInput
                                fullWidth={true}
                                name="cargosCant"
                                labelText="Cantidad de cargos"
                                disabled={cargosDisabled}
                                onChange={(e) => {
                                    formikRef.current.setFieldValue("cargosCant", e.target.value);
                                    const cargo = cargosFilter.filter(c => c.nqnCargoId === Number(formikRef?.current?.values?.cargoId))
                                    if (cargo.length === 0) {
                                        return
                                    }
                                    if (cargo[0].nqnCargoTipo === "D") {
                                        formikRef.current.setFieldValue("tipoHr", "diario");
                                        formikRef.current.setFieldValue("horas", cargo[0].nqnCargoHoras * e.target.value);
                                        formikRef.current.setFieldValue("horasLu", cargo[0].nqnCargoHoras * e.target.value);
                                        formikRef.current.setFieldValue("horasMa", cargo[0].nqnCargoHoras * e.target.value);
                                        formikRef.current.setFieldValue("horasMi", cargo[0].nqnCargoHoras * e.target.value);
                                        formikRef.current.setFieldValue("horasJu", cargo[0].nqnCargoHoras * e.target.value);
                                        formikRef.current.setFieldValue("horasVi", cargo[0].nqnCargoHoras * e.target.value);
                                        // setHr(true)
                                    } else if (cargo[0].nqnCargoTipo === "S") {
                                        formikRef.current.setFieldValue("tipoHr", "semanal");
                                        formikRef.current.setFieldValue("horas", cargo[0].nqnCargoHoras * e.target.value);
                                        if (cargo[0].nqnCargoHoras % 5 === 0) {
                                            formikRef.current.setFieldValue("horasLu", (cargo[0].nqnCargoHoras * e.target.value) / 5);
                                            formikRef.current.setFieldValue("horasMa", (cargo[0].nqnCargoHoras * e.target.value) / 5);
                                            formikRef.current.setFieldValue("horasMi", (cargo[0].nqnCargoHoras * e.target.value) / 5);
                                            formikRef.current.setFieldValue("horasJu", (cargo[0].nqnCargoHoras * e.target.value) / 5);
                                            formikRef.current.setFieldValue("horasVi", (cargo[0].nqnCargoHoras * e.target.value) / 5);
                                        } else {
                                            formikRef.current.setFieldValue("horasLu", "");
                                            formikRef.current.setFieldValue("horasMa", "");
                                            formikRef.current.setFieldValue("horasMi", "");
                                            formikRef.current.setFieldValue("horasJu", "");
                                            formikRef.current.setFieldValue("horasVi", "");
                                        }
                                        // setHr(false)
                                    } else {
                                        formikRef.current.setFieldValue("tipoHr", "");
                                        formikRef.current.setFieldValue("horas", "");
                                        formikRef.current.setFieldValue("horasLu", "");
                                        formikRef.current.setFieldValue("horasMa", "");
                                        formikRef.current.setFieldValue("horasMi", "");
                                        formikRef.current.setFieldValue("horasJu", "");
                                        formikRef.current.setFieldValue("horasVi", "");
                                        // setHr(false)
                                    }
                                }}
                            >
                                {cantidad?.map(({ name, id, value }) => (
                                    <option
                                        key={id}
                                        data-id={id}
                                        value={value}
                                    >
                                        {name}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Box>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="cargoId"
                                labelText="Cargo"
                                onChange={(e) => {
                                    formikRef.current.setFieldValue("cargoId", e.target.value);
                                    const cargo = cargosFilter.filter(c => c.nqnCargoId === Number(e.target.value))
                                    console.log(cargo)
                                    if (cargo[0].nqnCargoTipo === "D") {
                                        setCargosDisabled(false)
                                        formikRef.current.setFieldValue("tipoHr", "diario");
                                        formikRef.current.setFieldValue("horas", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        formikRef.current.setFieldValue("horasLu", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        formikRef.current.setFieldValue("horasMa", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        formikRef.current.setFieldValue("horasMi", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        formikRef.current.setFieldValue("horasJu", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        formikRef.current.setFieldValue("horasVi", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        setHr(true)
                                    } else if (cargo[0].nqnCargoTipo === "S") {
                                        setCargosDisabled(false)
                                        formikRef.current.setFieldValue("tipoHr", "semanal");
                                        formikRef.current.setFieldValue("horas", cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant);
                                        if (cargo[0].nqnCargoHoras % 5 === 0) {
                                            formikRef.current.setFieldValue("horasLu", (cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant) / 5);
                                            formikRef.current.setFieldValue("horasMa", (cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant) / 5);
                                            formikRef.current.setFieldValue("horasMi", (cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant) / 5);
                                            formikRef.current.setFieldValue("horasJu", (cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant) / 5);
                                            formikRef.current.setFieldValue("horasVi", (cargo[0].nqnCargoHoras * formikRef?.current?.values?.cargosCant) / 5);
                                        } else {
                                            formikRef.current.setFieldValue("horasLu", "");
                                            formikRef.current.setFieldValue("horasMa", "");
                                            formikRef.current.setFieldValue("horasMi", "");
                                            formikRef.current.setFieldValue("horasJu", "");
                                            formikRef.current.setFieldValue("horasVi", "");
                                        }
                                        setHr(false)
                                    } else {
                                        setCargosDisabled(true)
                                        formikRef.current.setFieldValue("cargosCant", 1)
                                        formikRef.current.setFieldValue("tipoHr", "");
                                        formikRef.current.setFieldValue("horas", "");
                                        formikRef.current.setFieldValue("horasLu", "");
                                        formikRef.current.setFieldValue("horasMa", "");
                                        formikRef.current.setFieldValue("horasMi", "");
                                        formikRef.current.setFieldValue("horasJu", "");
                                        formikRef.current.setFieldValue("horasVi", "");
                                        setHr(false)
                                    }
                                }}
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {cargosFilter?.map(({ nqnCargoId, nqnCargoNombre }) => (
                                    <option
                                        key={nqnCargoId}
                                        data-id={nqnCargoId}
                                        value={nqnCargoId}
                                    >
                                        {nqnCargoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikTextInput
                                fullWidth={true}
                                name="horas"
                                labelText="Horas"
                                disabled={hr}
                            />
                        </Box>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikTextInput
                                fullWidth={true}
                                name="horasLu"
                                labelText="Lunes"
                                disabled={hr}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasMa"
                                labelText="Martes"
                                disabled={hr}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasMi"
                                labelText="Miercoles"
                                disabled={hr}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasJu"
                                labelText="Jueves"
                                disabled={hr}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="horasVi"
                                labelText="Viernes"
                                disabled={hr}
                            />
                        </Box>
                    </Grid>
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
