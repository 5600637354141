import axiosPost from "./axiosPost";

export const rolGet = async (servicioCod, credentials) => {
    const body = {
        service: "RolGet",
        params: {
            servicioHooliCod: servicioCod,
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
