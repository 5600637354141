import axiosPost from "./axiosPost";

export const usuarioBasicosGet = async ({
    usuarioNroDeDocumento,
    usuarioSexo,
    usuarioFechaDeNacimiento,
}, credentials) => {
    const body = {
        service: "UsuarioBasicosGet",
        params: {
            usuarioNroDeDocumento,
            usuarioSexo,
            usuarioFechaDeNacimiento,
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
