import React, { useCallback, useEffect, useState } from "react";

import { FormButton } from "components";

import { formatDNI, transformDate } from "services/Local-services";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "../PersonalDataDashboard.module.scss";
import { Mapa } from "components";
import { clienteUsuarioGet } from "services/services-mia";
import Person from "assets/img/silueta.png";
import { Grid } from "@mui/material";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";

const onboardStatus = [
    {
        onboardStatusCod: '',
        onboardStatusNombre: 'Todos'
    },
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];

export default function DetailModal({ userData, updateList, servicio, closeModal }) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    console.log(user);
    const getUser = useCallback(async (id) => {
        setIsLoading(true);

        const body = {
            service: "MIADemoUsuarioFichaGet",
            params: {
                usuarioId: id,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackBarInfo
        );
        setUser(result[0]);

        setIsLoading(false);
    }, [credentials])

    useEffect(() => {
        getUser(userData.usuarioId);
    }, []);

    return (
        <Grid container columns={12} spacing={2}>
            <Grid item sm={8}>
                <Grid container columns={12} spacing={2}>
                    <Grid item sm={3}>
                        <div className={styles.imageContainer}>
                            <div className={styles.imageWrapper}>
                                <img
                                    src={user?.imagePathSELFIE || Person}
                                    alt="selfie"
                                    className={styles.selfie}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm={9}>
                        <div className={styles.dataContainer}>
                            <table className={styles.personalData}>
                                <tr>
                                    <td className={styles.label}>Nombre y Apellido</td>
                                    <td className={styles.data}>{`${user?.usuarioNombres} ${user?.usuarioApellidos}`}</td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>Número de documento</td>
                                    <td className={styles.data}>{formatDNI(user?.usuarioNroDeDocumentoX)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>Genero</td>
                                    <td className={styles.data}>
                                        {user?.usuarioSexo === "M"
                                            ? "Masculino"
                                            : user?.usuarioSexo === "F"
                                                ? "Femenino"
                                                : "No Binario"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>Fecha de nacimiento</td>
                                    <td className={styles.data}>{transformDate(user?.usuarioFechaDeNacimiento)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>CUIT</td>
                                    <td className={styles.data}>{user?.usuarioCUIT || "-"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>Email</td>
                                    <td className={styles.data}>{user?.usuarioMail || "-"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.label}>Celular</td>
                                    <td className={styles.data}>{user?.usuarioTelefonoCelular || "-"}</td>
                                </tr>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={4}>
                <div className={styles.cardContainer}>
                    <div className={styles.card}>
                        <div className={styles['card-label']}>Último Login</div>
                        <div className={styles['card-value']}>{user?.ultimaSesion ? transformDate(user.ultimaSesion) : 'No diponible'}</div>
                    </div>
                </div>
            </Grid>

            <Grid item sm={8}>
                <div className={styles.dataContainer}>
                    <div className={styles.title}>Domicilio</div>
                    <table className={styles.personalData}>
                        <tr>
                            <td className={styles.label}>Calle</td>
                            <td className={styles.data} colSpan={2}>{user?.domicilioCalle ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles.label}>Número</td>
                            <td className={styles.label}>Piso</td>
                            <td className={styles.label}>Departamento</td>
                        </tr>
                        <tr>
                            <td className={styles.data}>{user?.domicilioCasa ?? '-'}</td>
                            <td className={styles.data}>{user?.domicilioPiso ?? '-'}</td>
                            <td className={styles.data}>{user?.domicilioUnidad ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles.label}>Localidad</td>
                            <td className={styles.data} colSpan={2}>{user?.localidadNombre ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles.label}>País</td>
                            <td className={styles.label}>Provincia</td>
                            <td className={styles.label}>Código Postal</td>
                        </tr>
                        <tr>
                            <td className={styles.data}>{user?.paisNombre ?? '-'}</td>
                            <td className={styles.data}>{user?.provinciaNombre ?? '-'}</td>
                            <td className={styles.data}>{user?.domicilioCPA ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles.label}>Latitud</td>
                            <td className={styles.label}>Longitud</td>
                        </tr>
                        <tr>
                            <td className={styles.data}>{user?.primeraSesionLongitud ?? '-'}</td>
                            <td className={styles.data}>{user?.primeraSesionLatitud ?? '-'}</td>
                        </tr>
                    </table>
                </div>
            </Grid>

            <Grid item sm={4}>
                <div className={styles.mapContainer}>
                    <div className={styles.title}>Geolocalización</div>
                    <div className={styles.map}>
                        {
                            user?.primeraSesionLatitud && user?.primeraSesionLongitud
                                ? <span className={styles.geoLocation}>
                                    <Mapa
                                        lat={user?.primeraSesionLatitud}
                                        lng={user?.primeraSesionLongitud}
                                    />
                                </span>
                                : <span className={styles.geoLocation}>
                                    Geolocalización no registrada
                                </span>
                        }
                    </div>
                </div>
            </Grid>
            <FormButton
                onClick={closeModal}
                oneButton="true"
                // inRequest={inRequest || isLoading}
                newFormData={{ button: "Salir" }}
                onCloseModal={closeModal}
            />
        </Grid>
    );
}
