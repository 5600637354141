import axiosPost from "./axiosPost";

export const cargosGet = async (credentials, data) => {
    const body = {
        service: "NQNCargoGet",
        params: {
            nqnCargoId: data?.id,
            nqnCargoTipo: data?.tipo,
            nqnCargoNivelModalidadId: data?.nivelModalidadId,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
