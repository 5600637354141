import React, { useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { nivelModalidadIns } from "services/services-mia/nivelModalidadIns";

const activeValues = [
    { value: "Y", name: "Si", id: "1" },
    { value: "N", name: "No", id: "2" },
];

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const handleCreateNivel = async (values) => {
        const response = await nivelModalidadIns(
            credentials,
            values
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Nivel creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
                activo: "Y",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un código").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un código"),
                })
            }
            onSubmit={(values) => {
                handleCreateNivel(values);
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="activo"
                            labelText="¿Es activo?"
                        // onChange={handleChangePais}
                        >
                            {activeValues.map(({ value, name, id }) => (
                                <option
                                    key={id}
                                    data-id={id}
                                    value={value}
                                >
                                    {name}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
