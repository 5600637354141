import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = ({
    redirectProtectedRoute,
    isAuth
}) => {
    const location = useLocation();
    if (isAuth) return <Outlet />;
    return <Navigate to={redirectProtectedRoute} state={{ from: location }} replace />;
}

export default ProtectedRoute;
