import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { escuelasDel } from "services/services-mia/escuelasDel";
import { escuelasGet } from "services/services-mia/escuelasGet";

export default function DeleteModal({ user, updateList, servicio, closeModal, setterRowSelected }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getData = async () => {
        setIsLoading(true);
        const result = await escuelasGet(credentials, { id: user.id })
        formikRef.current.setValues({
            escuelaId: result[0].nqnEscuelaId,
            cue: result[0].nqnEscuelaCUE,
            name: result[0].nqnEscuelaNombre,
        });
        setData(result[0]);
        setIsLoading(false);
    }

    const handleDeleteEscuela = async (values) => {
        const response = await escuelasDel(
            credentials,
            { id: data.nqnEscuelaId }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Escuela eliminada correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                escuelaId: "",
                name: "",
                cue: "",
            }}
            validationSchema={
                yup.object({
                    name: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                    cue: yup.string("Debe ingresar un CUE").required("Debe ingresar un CUE"),
                })
            }
            onSubmit={(values) => {
                handleDeleteEscuela(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="name"
                            labelText="Nombre"
                            disabled
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cue"
                            labelText="CUE"
                            disabled
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
