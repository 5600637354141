import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { distritosGet } from "services/services-mia/distritosGet";
import { distritosDel } from "services/services-mia/distritosDel";

export default function DeleteModal({ user, updateList, servicio, closeModal, setterRowSelected }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getDistritoData = async () => {
        setIsLoading(true);
        const result = await distritosGet(credentials, user.id)
        formikRef.current.setValues({
            nombre: result[0].nqnDistritoNombre,
            cod: result[0].nqnDistritoCod,
        });
        setData(result[0]);
        setIsLoading(false);
    }

    const handleDeleteDistrito = async (values) => {
        const response = await distritosDel(
            credentials,
            { id: data.nqnDistritoId }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Distrito eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getDistritoData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe escribir el código del distrito").required("Debe escribir el código del distrito"),
                })
            }
            onSubmit={(values) => {
                handleDeleteDistrito(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                            disabled
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                            disabled
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
