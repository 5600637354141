import axiosPost from "./axiosPost";

export const serieFeriadoUpd = async (credentials, data) => {
    const body = {
        service: "NQNFeriadoSerieUpd",
        params: {
            nqnFeriadoSerieId: data.id,
            nqnFeriadoSerieFecha: data.date,
            nqnFeriadoSerieVersion: data.version,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result.status.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
