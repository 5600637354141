import axiosPost from "./axiosPost";

export const connectionRolGet = async (servicioCod, credentials) => {
    const body = {
        service: "ConnectionRolGet",
        params: {
            merchantId: credentials.merchantId,
            servicioHooliCod: servicioCod,
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
