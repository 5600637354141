export const PROVINCIAID = 20;

export const ESCUELAS_NAME = [
    {
        CUE: "580005700",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 8",
        descripcion2: "CAPITÁN DON JUAN DE SAN MARTÍN"
      },
  {
        CUE: "580018500",
        descripcion: "ESCUELA PRIMARIA 67",
        descripcion2: "CONTRAALMIRANTE MARTÍN GUERRICO"
      },
  {
        CUE: "580021100",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 12",
        descripcion2: "GRAL JOSE DE SAN MARTÍN"
      },
  {
        CUE: "580027400",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 6",
        descripcion2: "NIVEL INICIAL Y PRIMARIO"
      },
  {
        CUE: "580030800",
        descripcion: "ESCUELA ESPECIAL 7",
        descripcion2: "PARA CIEGOS Y DISMINUÍDOS VISUALES"
      },
  {
        CUE: "580036000",
        descripcion: "ESCUELA ESPECIAL 3",
        descripcion2: "MARÍA TERESA JABAT DE BIANCHI"
      },
  {
        CUE: "580036800",
        descripcion: "ESCUELA PRIMARIA 20",
        descripcion2: "INSPECTOR DANIEL EXEQUIEL GATICA"
      },
  {
        CUE: "580036900",
        descripcion: "ESCUELA PRIMARIA 82",
        descripcion2: "TERRITORIO NAC TIERRA DEL FUEGO ANTÁRTIDA"
      },
  {
        CUE: "580037500",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 18",
        descripcion2: "JULIO ARGENTINO ROCA"
      },
  {
        CUE: "580057200",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 14",
        descripcion2: "DOÑA GREGORIA MATORRAS DE SAN MARTÍN"
      },
  {
        CUE: "580060800",
        descripcion: "ESCUELA PRIMARIA 74",
        descripcion2: "GRAL MARTÍN MIGUEL DE GÜEMES"
      },
  {
        CUE: "580080500",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 5 TAYIL"
      },
  {
        CUE: "580081500",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 2"
      },
  {
        CUE: "580088900",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 12",
        descripcion2: "GRAL JOSÉ DE SAN MARTÍN NIVEL INICIAL"
      },
  {
        CUE: "580089000",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 12",
        descripcion2: "GRAL JOSÉ DE SAN MARTÍN NIVEL PRIMARIO"
      },
  {
        CUE: "580089100",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 12",
        descripcion2: "GRAL JOSÉ DE SAN MARTÍN NIVEL MEDIO"
      },
  {
        CUE: "580102300",
        descripcion: "CENTRO TERAP.EDUCAT. PARA NIÑOS",
        descripcion2: "Y JOV. CON TRAST.SEVEROS DE LA PER. QUELLUÉN"
      },
  {
        CUE: "580112600",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 6"
      },
  {
        CUE: "580119700",
        descripcion: "ESCUELA DE EDUCACIÓN DOMICILIARIA",
        descripcion2: "Y HOSPITALARIA 1"
      },
  {
        CUE: "580122905",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "TEXTIL E INDUMENTARIA"
      },
  {
        CUE: "580122907",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "SOLDADURA"
      },
  {
        CUE: "580123401",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 11"
      },
  {
        CUE: "580129500",
        descripcion: "INSTITUTO SUPERIOR PARA LA EDUCACIÓN",
        descripcion2: "TECNOLÓGICA Y PRODUCTIVA DEL NEUQUÉN"
      },
  {
        CUE: "580131900",
        descripcion: "INSTITUTO SUPERIOR DE FORMACIÓN TÉCNICA EN SALUD",
        descripcion2: "LIC. LUCRECIA BARISICH"
      },
  {
        CUE: "580003800",
        descripcion: "ESCUELA PRIMARIA 291",
        descripcion2: "ALBERGUE SR RAÚL JORGE OTAÑO"
      },
  {
        CUE: "580009500",
        descripcion: "ESCUELA PRIMARIA 119",
        descripcion2: "PRÓSPERO GUILLERMO ALEMANDRI"
      },
  {
        CUE: "580010600",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 10",
        descripcion2: "GRAL.ENRIQUE MOSCONI"
      },
  {
        CUE: "580011500",
        descripcion: "ESCUELA PRIMARIA 102",
        descripcion2: "YACIMIENTOS PETROLÍFEROS FISCALES"
      },
  {
        CUE: "580012200",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 1",
        descripcion2: "MARGARITA SALINAS DE PÁEZ"
      },
  {
        CUE: "580013612",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO PLAZA HUINCUL"
      },
  {
        CUE: "580045500",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 1",
        descripcion2: "NIVEL INICIAL Y PRIMARIA"
      },
  {
        CUE: "580045800",
        descripcion: "ESCUELA PRIMARIA 255",
        descripcion2: "AMÉRICO ANÍBAL JOSÉ VERDENELLI"
      },
  {
        CUE: "580056800",
        descripcion: "ESCUELA PRIMARIA 152",
        descripcion2: "ESTHER KRAITMAN DE KASTIKA"
      },
  {
        CUE: "580057000",
        descripcion: "JARDÍN DE INFANTES 15",
        descripcion2: "RAQUEL GRILLO DE ROSENBROCK"
      },
  {
        CUE: "580082500",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 1 MI FUTURO FELIZ"
      },
  {
        CUE: "580123407",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 21"
      },
  {
        CUE: "580123408",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 22"
      },
  {
        CUE: "580013607",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO ZAPALA"
      },
  {
        CUE: "580013615",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO MARIANO MORENO"
      },
  {
        CUE: "580041400",
        descripcion: "ESCUELA PRIMARIA 73",
        descripcion2: "REG.INFANTERÍA DE MONTAÑA 10"
      },
  {
        CUE: "580042700",
        descripcion: "ESCUELA PRIMARIA 185",
        descripcion2: "LIBERTADOR GENERAL JOSÉ DE SAN MARTÍN"
      },
  {
        CUE: "580047700",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 37",
        descripcion2: "DR. RENÉ GERÓNIMO FAVALORO"
      },
  {
        CUE: "580057100",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 13",
        descripcion2: "REPÚBLICA DE NICARAGUA"
      },
  {
        CUE: "580088700",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 13",
        descripcion2: "REPÚBLICA DE NICARAGUA NIVEL MEDIO"
      },
  {
        CUE: "580096400",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 13",
        descripcion2: "NIVEL INICIAL Y PRIMARIO"
      },
  {
        CUE: "580123409",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 31"
      },
  {
        CUE: "580123410",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 32"
      },
  {
        CUE: "580129901",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 98",
        descripcion2: "ANEXO LOS CATUTOS"
      },
  {
        CUE: "580048300",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 4",
        descripcion2: "GENDARMERÍA NACIONAL"
      },
  {
        CUE: "580048500",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 7",
        descripcion2: "MALVINAS ARGENTINAS"
      },
  {
        CUE: "580123411",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 41"
      },
  {
        CUE: "580003200",
        descripcion: "ESCUELA PRIMARIA 15",
        descripcion2: "DESIDERIA LANDESTOY DE MEDRANO"
      },
  {
        CUE: "580013617",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO TAQUIMILÁN"
      },
  {
        CUE: "580014400",
        descripcion: "ESCUELA PRIMARIA 79",
        descripcion2: "ALBERGUE BATALLA DE CHACABUCO"
      },
  {
        CUE: "580014500",
        descripcion: "ESCUELA PRIMARIA 66",
        descripcion2: "DON JORGE EMILIO DE VEGA PESSINO"
      },
  {
        CUE: "580014501",
        descripcion: "ESCUELA PRIMARIA 66",
        descripcion2: "DON JORGE EMILIO DE VEGA PESSINO ANEXO ARROYO BLANCO"
      },
  {
        CUE: "580018800",
        descripcion: "ESCUELA PRIMARIA 70",
        descripcion2: "ALBERGUE DIRECTOR HÉCTOR ALBERTO JOFRÉ"
      },
  {
        CUE: "580018900",
        descripcion: "ESCUELA PRIMARIA 68",
        descripcion2: "CACIQUE FRANCISCO HUAYQUILLAN"
      },
  {
        CUE: "580030400",
        descripcion: "INSTITUTO DE FORMACIÓN DOCENTE 2",
        descripcion2: "DPTO DE APLICACIÓN"
      },
  {
        CUE: "580037800",
        descripcion: "ESCUELA PRIMARIA 112",
        descripcion2: "AMALIA JESUSA URETA DE FUNES"
      },
  {
        CUE: "580040201",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 30",
        descripcion2: "ANEXO COLIPILLI ARRIBA"
      },
  {
        CUE: "580082201",
        descripcion: "CENTRO DE INICIACIÓN ARTÍSTICA 4",
        descripcion2: "ANEXO BUTA RANQUIL"
      },
  {
        CUE: "580099812",
        descripcion: "CENTRO PROVINCIAL DE UNIDADES DE DETENCIÓN 1",
        descripcion2: "ANEXO CHOS MALAL"
      },
  {
        CUE: "580114400",
        descripcion: "CENTRO DE FORMACIÓN PROFESIONAL 7",
        descripcion2: "DEL AULA A LA VIDA"
      },
  {
        CUE: "580122902",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "SOLDADURA"
      },
  {
        CUE: "580123413",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 51"
      },
  {
        CUE: "580123700",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 8"
      },
  {
        CUE: "580130903",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 100",
        descripcion2: "ANEXO CHORRIACA"
      },
  {
        CUE: "580024500",
        descripcion: "ESCUELA PRIMARIA 105",
        descripcion2: "FRANCISCO HUMBERTO RODRÍGUEZ"
      },
  {
        CUE: "580025200",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 1",
        descripcion2: "GRAL. SAN MARTÍN"
      },
  {
        CUE: "580093711",
        descripcion: "APADIC ASOCIACIÓN DE PADRES",
        descripcion2: "Y AMIGOS DEL DISCAPACITADO"
      },
  {
        CUE: "580114901",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 22",
        descripcion2: "ANEXO"
      },
  {
        CUE: "580122901",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "INSTALACIONES DOMICILIARIAS"
      },
  {
        CUE: "580123800",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 7"
      },
  {
        CUE: "580129904",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 98",
        descripcion2: "ANEXO AGUADA SAN ROQUE"
      },
  {
        CUE: "580002303",
        descripcion: "JARDÍN DE INFANTES 19 HUNELUE",
        descripcion2: "ANEXO ESCUELA PRIMARIA 170"
      },
  {
        CUE: "580003000",
        descripcion: "ESCUELA PRIMARIA 75",
        descripcion2: "ALBERGUE DON ENRIQUE CHEUQUEL"
      },
  {
        CUE: "580013605",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO LONCOPUE"
      },
  {
        CUE: "580037000",
        descripcion: "ESCUELA PRIMARIA 50",
        descripcion2: "DON JAIME FRANCISCO DE NEVARES"
      },
  {
        CUE: "580040800",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 5",
        descripcion2: "EJÉRCITO ARGENTINO"
      },
  {
        CUE: "580041000",
        descripcion: "ESCUELA PRIMARIA 332",
        descripcion2: "JULIO EUSTASIO GUIÑAZÚ ALANIZ"
      },
  {
        CUE: "580059600",
        descripcion: "ESCUELA PRIMARIA 6",
        descripcion2: "FRAY JUSTO SANTA MARÍA DE ORO"
      },
  {
        CUE: "580129902",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 98",
        descripcion2: "ANEXO HUNCAL"
      },
  {
        CUE: "580005802",
        descripcion: "ESCUELA SUPERIOR DE BELLAS ARTES MANUEL BELGRANO",
        descripcion2: "ANEXO OESTE"
      },
  {
        CUE: "580034300",
        descripcion: "ESCUELA PRIMARIA 101",
        descripcion2: "PAULA ALBARRACÍN DE SARMIENTO"
      },
  {
        CUE: "580045100",
        descripcion: "ESCUELA PRIMARIA 200",
        descripcion2: "MÉDICO RURAL DR RENÉ FAVALORO"
      },
  {
        CUE: "580101900",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 69",
        descripcion2: "COMPAÑERO CARLOS FUENTEALBA"
      },
  {
        CUE: "580103500",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 17",
        descripcion2: "RODOLFO ALEJANDRO TORRISI"
      },
  {
        CUE: "580123402",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 12"
      },
  {
        CUE: "580123403",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 16"
      },
  {
        CUE: "580026300",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 12",
        descripcion2: "ING. JUAN CARLOS FONTANIVE"
      },
  {
        CUE: "580026302",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 12",
        descripcion2: "ANEXO"
      },
  {
        CUE: "580099818",
        descripcion: "CENTRO EDUCATIVO PROVINCIAL INTEGRAL 1",
        descripcion2: "ANEXO CENTRO DE DETENIDOS SAN MARTIN DE LOS ANDES"
      },
  {
        CUE: "580107200",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 3"
      },
  {
        CUE: "580122906",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "MECÁNICA DE MOTOS"
      },
  {
        CUE: "580123412",
        descripcion: "ESCUELA PARA ADULTOS 15",
        descripcion2: "SEDE UNIDAD DE DETENCIÓN 42"
      },
  {
        CUE: "580004900",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 8",
        descripcion2: "DR ALBERTO PLOTTIER"
      },
  {
        CUE: "580019600",
        descripcion: "ESCUELA PRIMARIA 240",
        descripcion2: "INTEGRACIÓN LATINOAMERICANA"
      },
  {
        CUE: "580037700",
        descripcion: "ESCUELA PROVINCIAL DE ENSEÑANZA TÉCNICA 9",
        descripcion2: "EDUARDO VÍCTOR GATTI"
      },
  {
        CUE: "580051600",
        descripcion: "JARDÍN DE INFANTES 18",
        descripcion2: "INTEGRACIÓN LATINOAMERICANA"
      },
  {
        CUE: "580061800",
        descripcion: "ESCUELA PRIMARIA 98",
        descripcion2: "REMEDIOS ESCALADA DE SAN MARTÍN"
      },
  {
        CUE: "580099817",
        descripcion: "CENTRO EDUCATIVO PROVINCIAL INTEGRAL 1",
        descripcion2: "ANEXO SENILLOSA"
      },
  {
        CUE: "580103400",
        descripcion: "ESCUELA PRIMARIA 351",
        descripcion2: "PIONEROS Y PIONERAS DE PLOTTIER"
      },
  {
        CUE: "580112500",
        descripcion: "ESCUELA INTEGRAL DE ADOLESCENTES",
        descripcion2: "Y JÓVENES CON DISCAPACIDAD 4"
      },
  {
        CUE: "580129501",
        descripcion: "INSTITUTO SUPERIOR PARA LA EDUCACIÓN TECNOLÓGICA",
        descripcion2: "Y PRODUCTIVA DEL NEUQUÉN ANEXO VILLA EL CHOCÓN"
      },
  {
        CUE: "580013601",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO ALUMINE"
      },
  {
        CUE: "580032900",
        descripcion: "ESCUELA PRIMARIA 209",
        descripcion2: "MAESTRA MARÍA AMALIA CAYROL"
      },
  {
        CUE: "580060500",
        descripcion: "ESCUELA PRIMARIA 57",
        descripcion2: "ALBERGUE IDA FIUMANI DE ARELLANO"
      },
  {
        CUE: "580097600",
        descripcion: "RUPU QUINTULU HUECHE",
        descripcion2: "ALBERGUE PARROQUIAL ESTUDIANTIL"
      },
  {
        CUE: "580099200",
        descripcion: "CENTRO DE EDUCACIÓN FÍSICA 14",
        descripcion2: "PROFESOR GUSTAVO DANIEL VISCIGLIA"
      },
  {
        CUE: "580013608",
        descripcion: "INSTITUTO PROVINCIAL DE EDUCACIÓN TERCIARIA 1",
        descripcion2: "ANEXO RINCÓN DE LOS SAUCES"
      },
  {
        CUE: "580122903",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "METALMECÁNICA"
      },
  {
        CUE: "580129903",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 98",
        descripcion2: "ANEXO OCTAVIO PICO"
      },
  {
        CUE: "580130901",
        descripcion: "CENTRO PROVINCIAL DE ENSEÑANZA MEDIA 100",
        descripcion2: "ANEXO SANTO TOMAS"
      },
  {
        CUE: "580015600",
        descripcion: "ESCUELA PRIMARIA 162",
        descripcion2: "PRIMERA AUTORIDAD CIVIL DEL NEUQUEN"
      },
  {
        CUE: "580080303",
        descripcion: "CENTRO DE FORMACIÓN PROFESIONAL 16",
        descripcion2: "ANEXO LOS MICHES"
      },
  {
        CUE: "580122904",
        descripcion: "UNIDAD DE CULTURA TECNOLÓGICA AULA TALLER MÓVIL",
        descripcion2: "ENERGÍAS RENOVABLES"
      },
  ]