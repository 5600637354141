export const ACTIONS = {
    DELETE: "delete",
    INSERT: "insert",
    UPDATE: "update",
    STATUS: "status",
    QR: "qr",
    HISTORY: "history",
    ERROR: "error",
    GET: "get",
    EXPORT: "export",
    DETAIL: "detail",
    VIEW: "view",
};
