import axiosPost from "./axiosPost";

export const RNCAResumenGet = async (usuarioId, credentials) => {
    const body = {
        service: "RNCAResumenGet",
        params: {
            usuarioId: usuarioId,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
