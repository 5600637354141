import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikDateInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { getFeriados } from "services/services-mia";
// import { FormikDateInput } from "components/Filters/FormikInputs";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { SerieFeriadoIns } from "services/services-mia/serieFeriadoIns";

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [data, setData] = useState([]);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState([
        { value: "N", name: "Nacional", id: "1" },
        { value: "P", name: "Provincial", id: "2" },
        { value: "J", name: "Jornada Pedagogica", id: "3" }
    ]);
    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getFeriadosData = async () => {
        setIsLoading(true);
        const result = await getFeriados(credentials)
        setData(result);
        setIsLoading(false);
    }

    const handleCreateFeriado = async (values) => {
        console.log(values)
        const response = await SerieFeriadoIns(
            credentials,
            { id: values.nombre, date: values.date }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Serie de Feriado creada correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    useEffect(() => {
        getFeriadosData()
    }, []);
    const dataFormik = {
        date: { label: "Fecha" },
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                type: "",
                date: ""
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                    type: yup.string("Debe seleccionar un tipo").required("Debe seleccionar un tipo"),
                    date: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                })
            }
            onSubmit={(values) => {
                handleCreateFeriado(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("nombre", e.target.value);
                                let tipo = data.filter((item) => item.nqnFeriadoId === Number(e.target.value))
                                formikRef.current.setFieldValue("type", tipo[0].nqnFeriadoTipo === "N" ? "Nacional" : tipo[0].nqnFeriadoTipo === "P" ? "Provincial" : "Jornada Pedagogica");
                            }}
                        >
                            <option value={""} selected disabled >
                                Seleccione
                            </option>
                            {data.map(({ nqnFeriadoId, nqnFeriadoNombre, nqnFeriadoTipo }) => (
                                <option
                                    key={nqnFeriadoId}
                                    data-id={nqnFeriadoId}
                                    value={nqnFeriadoId}
                                >
                                    {nqnFeriadoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="type"
                            labelText="Tipo"
                            disabled
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="date"
                            labelText="Fecha"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("date", e.target.value);
                            }}
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
