import React, { useCallback, useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rioNegroServices,
    routeInfo,
    userCredentials,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { servicioDashboardGet } from "services/services-mia/servicioDashboardGet";


export default function Dashboard() {
    const [serviceDetails, setServiceDetails] = useState({})
    const credentials = useRecoilValue(userCredentials);
    const services = useRecoilValue(rioNegroServices);
    const setRoute = useSetRecoilState(routeInfo);

    const updateServiceDetails = useCallback(async () => {
        const { result } = await servicioDashboardGet({}, credentials);

        const newServiceDetail = {};
        for (const service of result || []) {
            if (!newServiceDetail[service.servicioCod]) newServiceDetail[service.servicioCod] = []
            newServiceDetail[service.servicioCod].push({
                merchantServicioUsuarioStatus: service.merchantServicioUsuarioStatus,
                conOnboard: service.conOnboard,
                totalUsuarios: service.totalUsuarios
            })
        }
        setServiceDetails(newServiceDetail);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    useEffect(() => {
        if (services.length > 0) {
            updateServiceDetails()
        }
    }, [services.length, updateServiceDetails])

    return (
        <main className={styles.main}>
            <section className={styles.section}>
                <span className={styles.title}>
                    <h1 className={styles.welcome}>
                        ¡Hola {credentials.merchantNombre}!
                    </h1>

                    <p className={styles.phrase}>Gestioná tu provincia en un sólo lugar.</p>
                </span>


            </section>
        </main>
    );
}
