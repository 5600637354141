import axiosPost from "./axiosPost";

export const servicioDashboardGet = async ({
    merchantId = null,
}, credentials) => {
    const body = {
        service: "MIAServicioUsuarioGet",
        params: {
            merchantId: credentials.merchantId ?? merchantId,
        },
    };

    return await axiosPost(body, credentials);
};