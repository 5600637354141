import axiosPost from "./axiosPost";

export const tipoDeDocumentoGet = async (paisId, credentials) => {
    const body = {
        service: "TipoDeDocumentoGet",
        params: {
            paisId: paisId,
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
