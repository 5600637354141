import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useRecoilValue } from "recoil";
import {
    userCredentials,
} from "recoilState/GlobalState";
import { CircularIndeterminate, FormButton } from "components";
import { Box } from "@mui/material";
import { docenteCalendarioGet } from "services/services-mia/docentesCalendarioGet";
import styles from "../Docentes.module.scss"
import { CalendarMonthRounded } from '@mui/icons-material';
import { InfoCard } from "components/InfoCard/InfoCard";

export default function StatusModal({ user, servicio, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setIsLoading(true);
        const result = await docenteCalendarioGet(credentials, { id: user.id })
        setData(result);
        setIsLoading(false);
    }

    useEffect(() => {
        getData()
    }, []);
    return (
        <>
            {isLoading ? <CircularIndeterminate /> : <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} width={"100%"} gap={2} marginBlock={"10px"}>
                <InfoCard nombre={user.name} apellido={user.lastname} escuela={user.escuelaNombre} cargo={user.cargoNombre} />
                <div style={{ width: "100%", borderRadius: "10px", border: "1px solid #E5E9F0" }}>
                    <div style={{ backgroundColor: "#eee", display: "flex", justifyContent: "space-between", padding: "12px", fontSize: "18px", color: "#595959" }}>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%" }}><p style={{ margin: "0" }}>Usuario</p></div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%" }}><p style={{ margin: "0" }}>Modificación</p></div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%" }}><p style={{ margin: "0" }}>Fecha</p></div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%" }}><p style={{ margin: "0" }}>Horas totales</p></div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%" }}><p style={{ margin: "0" }}>Calendario</p></div>
                    </div>
                    <div style={{ fontSize: "18px", color: "#5F6689", textAlign: "center", minHeight: "20px" }}>
                        {data.length === 0 ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <p>Sin resultados</p>
                            </div> : data.map((item, i) => (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #e5e9f0", padding: "8px 12px" }} key={i}>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%", height: "fit-content" }}>{`${item.usuarioApellidos}${item.usuarioNombres ? "," : ""} ${item.usuarioNombres ? item.usuarioNombres : ""}`}</div>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%", height: "fit-content" }}>{item.nqnDocenteCalendarioUFH ? moment(item.nqnDocenteCalendarioUFH).format("DD/MM/YYYY HH:mm:ss").toString() : null}</div>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%", height: "fit-content" }}>{item.nqnDocenteCalendarioFecha ? moment(item.nqnDocenteCalendarioFecha).format("DD/MM/YYYY").toString() : null}</div>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%", height: "fit-content" }}>{item.nqnDocenteCalendarioHoras === null ? "Baja" : item.nqnDocenteCalendarioHoras}</div>
                                    <div style={{ display: "flex", flex: "1", justifyContent: "center", width: "100%", height: "fit-content" }}><div className={styles.container}>
                                        <div className={styles.containerBtn}>
                                            <CalendarMonthRounded />
                                        </div>
                                        <div className={styles.containerHoverCenter}>
                                            <div className={styles.containerData}>
                                                <p>Lunes</p>
                                                <p>{item.nqnDocenteCalendarioHorasLu ? item.nqnDocenteCalendarioHorasLu : 0}hs</p>
                                            </div>
                                            <div className={styles.containerData}>
                                                <p>Martes</p>
                                                <p>{item.nqnDocenteCalendarioHorasMa ? item.nqnDocenteCalendarioHorasMa : 0}hs</p>
                                            </div>
                                            <div className={styles.containerData}>
                                                <p>Miercoles</p>
                                                <p>{item.nqnDocenteCalendarioHorasMi ? item.nqnDocenteCalendarioHorasMi : 0}hs</p>
                                            </div>
                                            <div className={styles.containerData}>
                                                <p>Jueves</p>
                                                <p>{item.nqnDocenteCalendarioHorasJu ? item.nqnDocenteCalendarioHorasJu : 0}hs</p>
                                            </div>
                                            <div className={styles.containerData}>
                                                <p>Viernes</p>
                                                <p>{item.nqnDocenteCalendarioHorasVi ? item.nqnDocenteCalendarioHorasVi : 0}hs</p>
                                            </div>
                                        </div>
                                    </div></div>
                                </div>
                            ))}
                    </div>
                </div>
            </Box>}
            <FormButton
                // inRequest={inRequest || isLoading}
                newFormData={{ button: "Salir" }}
                onCloseModal={closeModal}
                onClick={closeModal}
                oneButton={true}
            />
        </>
    );
}
