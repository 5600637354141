import React from 'react'
import styles from "./InfoCard.module.scss"

export const InfoCard = ({ nombre, apellido, escuela, cargo }) => {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <p className={styles.title}><b>Docente:</b> </p>
                <p className={styles.data}>{`${nombre}, ${apellido}`}</p>
            </div>
            <div className={styles.row}>
                <p className={styles.title}><b>Escuela:</b> </p>
                <p className={styles.data}>{`${escuela}`}</p>
            </div>
            <div className={styles.row}>
                <p className={styles.title}><b>Cargo:</b> </p>
                <p className={styles.data}>{`${cargo}`}</p>
            </div>
        </div>
    )
}
