import axiosPost from "./axiosPost";

export const RNCAAsignacionDo = async (data, credentials) => {
    const {
        usuarioNroDeDocumento,
        usuarioSexo,
        rncaMovimientoCantidad,
        rncaMovimientoStatusFecha,
        rncaMovimientoLatitud,
        rncaMovimientoLongitud,
    } = data;
    const body = {
        service: "RNCAAsignacionDo",
        params: {
            usuarioNroDeDocumento,
            usuarioSexo,
            rncaMovimientoCantidad,
            rncaMovimientoStatusFecha,
            rncaMovimientoLatitud,
            rncaMovimientoLongitud,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
