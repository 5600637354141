import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const libraries = ["places"];

export default function Mapa(props) {
    const { lat, lng, style } = props;
    const center = {
        lat: lat
            ? typeof lat === "number"
                ? lat
                : parseFloat(lat)
            : -34.6036844,
        lng: lng
            ? typeof lng === "number"
                ? lng
                : parseFloat(lng)
            : -58.3815591,
    };

    const containerStyle = {
        width: "100%",
        height: "100%",
        ...style,
    };

    return (
        <LoadScript
            id="google-map-script"
            googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
            libraries={libraries}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
            >
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
}
