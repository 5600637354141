import axiosPost from "../axiosPost";

export const loginQREvent = async ({
    codigoQR
}) => {
    const body = {
        service: "LoginQREvent",
        params: {
            codigoQR,
        },
    };
    return await axiosPost(body);
};
