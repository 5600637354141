import axiosPost from "./axiosPost";

export const clienteUsuarioGet = async ({
    tipoRelacion,
    usuarioId = null,
    merchantServicioUsuarioStatus=null,
    page = null,
    pageSize = null,
    usuarioTieneApp = null,
    usuarioNroDeDocumentoX = null,
    usuarioApellidos = null,
    rolCod = null,
    fechaDesde = null,
    fechaHasta = null,
    fechaTipo = 'ESTADO',
}, credentials) => {
    const body = {
        service: "NQNUsuarioGet",
        params: {
            usuarioId: usuarioId,
            merchantServicioUsuarioTiporel: tipoRelacion,
            rolCod: rolCod,
            page: page,
            pageSize: pageSize,
            usuarioTieneApp: usuarioTieneApp,
            usuarioNroDeDocumentoX: usuarioNroDeDocumentoX,
            usuarioApellidos: usuarioApellidos,
            merchantServicioUsuarioStatus: merchantServicioUsuarioStatus,
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta,
            fechaTipo: fechaTipo,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return {result: result.result,
                total: result.status.id
        };
    }
};
