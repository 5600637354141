import axiosPost from "./axiosPost";

export const SerieFeriadoIns = async (credentials, data) => {
    const body = {
        service: "NQNFeriadoSerieIns",
        params: {
            nqnFeriadoId: data.id,
            nqnFeriadoSerieFecha: data.date,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result.status.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
