import axiosPost from "./axiosPost";

export const RNESInformeGet = async (credentials, data) => {
    const {
        RNESDespachanteId,
        RNESReceptorId,
        RNESArticuloId,
        fechaDesde,
        fechaHasta,
    } = data;
    const body = {
        service: "RNESInformeGet",
        params: {
            RNESDespachanteId,
            RNESReceptorId,
            RNESArticuloId,
            fechaDesde,
            fechaHasta,
        },
    };

    const result = await axiosPost(body, credentials);

    return result.result;
};
