import { PROVINCIAID } from "utils/constants";
import axiosPost from "./axiosPost";

export const localidadesGet = async (credentials) => {
    const body = {
        service: "LocalidadGet",
        params: {
            provinciaId: PROVINCIAID
        },
    };
    const result = await axiosPost(body, credentials);
    return result.result;
};
