import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { clienteUsuarioIns } from "services/services-mia/clienteUsuarioIns";
import { nivelModalidadGet } from "services/services-mia/nivelModalidadGet";
import Modal from "components/UI/Modal/Modal";
import InfoIcon from '@mui/icons-material/Info';

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];

const emailRegex = /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [nivel, setNivel] = useState([]);
    const [editNivel, setEditNivel] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };

    const getData = async () => {
        const result = await nivelModalidadGet(
            credentials,
        );
        setNivel(result);
    }
    const handleCreateUser = async (values) => {
        const { result, status } = await clienteUsuarioIns(
            values,
            credentials,
        );
        if (result?.length === 0) {
            setSnackBarInfo({
                message: "Usuario creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    const closeModalDetail = () => {
        setModalInfo(false);
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            <Modal open={modalInfo} onClose={closeModalDetail}>
                <FormButton
                    onClick={closeModalDetail}
                    oneButton="true"
                    // inRequest={inRequest || isLoading}
                    newFormData={{ button: "Salir" }}
                    onCloseModal={closeModalDetail}
                />
            </Modal>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    dni: "",
                    mail: "",
                    sexo: "",
                    name: "",
                    lastname: "",
                    rol: "",
                    cel: "",
                    nivelId: "",
                }}
                validationSchema={
                    yup.object({
                        dni: yup.string().max(8, "Debe tener menos de 15 caracteres").required("El DNI es requerido"),
                        sexo: yup.string().required("El sexo es requerido"),
                        rol: yup.string().required("El rol es requerido"),
                        mail: yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Debe ingresar un formato correcto"),
                        // cel: yup.string().matches(/^(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g, "Debe ingresar un formato correcto (10 dígitos)")
                    })
                }
                onSubmit={(values) => {
                    handleCreateUser(values);
                }}
            >
                {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                    <div className={styles["column-sm"]}>
                        {<>
                            <FormikTextInput
                                fullWidth={true}
                                name="dni"
                                labelText="Número de Documento*"
                            />
                            <FormikSelectInput
                                fullWidth={true}
                                name="sexo"
                                labelText="Genero*"
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {sexos.map(({ sexoCod, sexoNombre }) => (
                                    <option
                                        key={sexoCod}
                                        data-id={sexoCod}
                                        value={sexoCod}
                                    >
                                        {sexoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikTextInput
                                fullWidth={true}
                                name="lastname"
                                labelText="Apellidos"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="name"
                                labelText="Nombres"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="mail"
                                labelText="Mail"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="cel"
                                labelText="Telefono"
                            />
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", width: "100%" }}>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="rol"
                                    labelText="Rol*"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("rol", e.target.value);
                                        let rolType = roles.find((rol) => rol.rolCod === e.target.value);
                                        if (rolType.nqnPermisoRolNivel === "NM") {
                                            setEditNivel(true);
                                        } else {
                                            setEditNivel(false);
                                            formikRef.current.setFieldValue("nivelId", "");
                                        }
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {roles.map(({ puedeCrear, rolCod, rolNombre }) => (
                                        puedeCrear === "Y" && <option
                                            key={rolCod}
                                            data-id={rolCod}
                                            value={rolCod}
                                        >
                                            {rolNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                                <div onClick={() => setModalInfo(true)} style={{ margin: "0 10px", cursor: "pointer" }}>
                                    <InfoIcon style={{ color: "#2B3E4C " }} />
                                </div>
                            </div>
                            <FormikSelectInput
                                fullWidth={true}
                                name="nivelId"
                                labelText="Nivel"
                                disabled={!editNivel}
                                style={{ visibility: editNivel ? "visible" : "hidden" }}
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {nivel.map(({ nqnNivelModalidadId, nqnNivelModalidadNombre }) => (
                                    <option
                                        key={nqnNivelModalidadId}
                                        data-id={nqnNivelModalidadId}
                                        value={nqnNivelModalidadId}
                                    >
                                        {nqnNivelModalidadNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </>}
                        <FormButton
                            inRequest={inRequest || isLoading}
                            newFormData={{ button: "Agregar" }}
                            onCloseModal={closeModal}
                        />
                    </div>
                </Form>}
            </Formik></>
    );
}
