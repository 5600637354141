import axiosPost from "./axiosPost";

export const escuelasStatus = async (credentials, data) => {
    const body = {
        service: "NQNEscuelaStatusUpd",
        params: {
            nqnEscuelaId: data.escuelaId,
            nqnEscuelaStatusCod: data.status,
            nqnEscuelaStatusMotivo: data.motivo,
            nqnEscuelaStatusFecha: data.statusFecha,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
