import axiosPost from "./axiosPost";

export const feriadoUpd = async (credentials, data) => {
    const body = {
        service: "NQNFeriadoUpd",
        params: {
            nqnFeriadoId: data.id,
            nqnFeriadoNombre: data.name,
            nqnFeriadoTipo: data.type,
            nqnFeriadoVersion: data.version,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
