import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput, FormikYesOrNoInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { Box, Grid } from "@mui/material";
import { cargosIns } from "services/services-mia/cargosIns";
import { nivelModalidadGet } from "services/services-mia/nivelModalidadGet";

const type = [
    { value: "D", name: "Diario", id: "1" },
    { value: "S", name: "Semanal", id: "2" },
    { value: "C", name: "Cátedra", id: "3" }
];

export default function InsertModal({ updateList, closeModal }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hr, setHr] = useState(false);
    const [nivelModalidad, setNivelModalidad] = useState([]);

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getData = async () => {
        setIsLoading(true);
        const result = await nivelModalidadGet(credentials, { activo: "Y" })
        setNivelModalidad(result);
        setIsLoading(false);
    }
    const handleCreatCargo = async (values) => {
        console.log(values)
        const response = await cargosIns(
            credentials,
            values
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Cargo creado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    useEffect(() => {
        getData()
    }, []);
    console.log(formikRef?.current?.values)
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                cod: "",
                name: "",
                type: "",
                horas: 0,
                nivelModalidad: "",
                esDirectivo: false,
                esVisible: true
            }}
            validationSchema={
                yup.object({
                    cod: yup.string().max(15, "Debe tener menos de 15 caracteres").required("El código es requerido"),
                    name: yup.string().max(90, "Debe tener menos de 90 caracteres").required("El nombre es requerido"),
                    type: yup.string().required("El tipo es requerido"),
                    horas: yup.number().when("type", { is: "D", then: yup.number().max(20, "Debe ser menor a 20") }).when("type", { is: "S", then: yup.number().max(100, "Debe ser menor a 100") }).required("Las horas son requeridas"),
                    nivelModalidad: yup.string().required("El nivel de modalidad es requerido"),
                })
            }
            onSubmit={(values) => {
                handleCreatCargo(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <Grid width={"100%"}>
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="name"
                            labelText="Nombre"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="nivelModalidad"
                            labelText="Nivel"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("nivelModalidad", e.target.value);
                            }}
                        >
                            <option value={""} selected disabled >
                                Seleccione
                            </option>
                            <option value={null} data-id={null} data-name={null}>
                                Ninguno
                            </option>
                            {nivelModalidad.map(({ nqnNivelModalidadId, nqnNivelModalidadNombre }) => (
                                <option
                                    key={nqnNivelModalidadId}
                                    data-id={nqnNivelModalidadId}
                                    value={nqnNivelModalidadId}
                                >
                                    {nqnNivelModalidadNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="type"
                            labelText="Tipo"
                            onChange={(e) => {
                                formikRef.current.setFieldValue("type", e.target.value);
                                if (e.target.value === "C") {
                                    formikRef.current.setFieldValue("horas", "0");
                                    setHr(true);
                                } else {
                                    setHr(false);
                                }
                            }}
                        >
                            <option value={""} selected disabled >
                                Seleccione
                            </option>
                            {type.map(({ value, name }) => (
                                <option
                                    key={value}
                                    data-id={value}
                                    value={value}
                                >
                                    {name}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="horas"
                            labelText="Horas"
                            disabled={hr}
                        />
                        <Box display={"flex"} alignItems={"center"} justifyContent={"start"}>
                            <FormikYesOrNoInput
                                labelText="¿Es directivo?"
                                name="esDirectivo"
                            />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"start"}>
                            <FormikYesOrNoInput
                                labelText="¿Es visible?"
                                name="esVisible"
                            />
                        </Box>
                    </Grid>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Crear" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>}
        </Formik>
    );
}
