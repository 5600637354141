import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { distritosGet } from "services/services-mia/distritosGet";
import { distritosUpd } from "services/services-mia/distritosUpd";

export default function UpdateModal({ updateList, user, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getDistritoData = async () => {
        setIsLoading(true);
        const result = await distritosGet(credentials, user.id)
        formikRef.current.setValues({
            nombre: result[0].nqnDistritoNombre,
            cod: result[0].nqnDistritoCod,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await distritosUpd(credentials, {
            id: data.nqnDistritoId,
            name: values.nombre,
            cod: values.cod,
            version: data.nqnDistritoVersion,
        })
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Distrito modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getDistritoData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                nombre: "",
                cod: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe escribir el código del distrito").max(15, "Debe tener menos de 15 caracteres").required("Debe escribir el código del distrito"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
