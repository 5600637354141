import React, { useEffect, useRef, useState, version } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput, FormikDateInput, FormikSelectInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { getFeriados } from "services/services-mia";
import { serieFeriadoUpd } from "services/services-mia/serieFeriadoUpd";
import { getSerieFeriado } from "services/services-mia/serieFeriadoGet";
import { cicloGet } from "services/services-mia/cicloGet";
import { cicloUpd } from "services/services-mia/cicloUpd";

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [type, setType] = useState([
        { value: "N", name: "Nacional", id: "1" },
        { value: "P", name: "Provincial", id: "2" },
        { value: "J", name: "Jornada Pedagogica", id: "3" }
    ]);
    const formikRef = useRef();
    const [dataCiclo, setDataCiclo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getCicloData = async () => {
        setIsLoading(true)
        const result = await cicloGet(credentials, {
            cicloId: user.id,
        })
        formikRef.current.setValues({
            año: result[0].nqnCalendarioCicloAnioLectivo,
            calendarioId: result[0].nqnCalendarioId,
            fechaDesde: result[0].nqnCalendarioCicloFechaDesde,
            fechaHasta: result[0].nqnCalendarioCicloFechaHasta,
            tramo: result[0].nqnCalendarioCicloTramo,
            calendarioNombre: result[0].nqnCalendarioNombre,
            version: result[0].nqnCalendarioCicloVersion,
            cicloId: result[0].nqnCalendarioCicloId
        });
        setDataCiclo(result)
        setIsLoading(false)
    }
    const handleSubmit = async (values) => {
        const year = values.fechaDesde.split("-")
        if (Number(year[0]) !== Number(values.año)) {
            setSnackBarInfo({
                message: "El año no coincide con la fecha de inicio",
                severity: "warning",
                open: true,
            });
        } else {
            const response = await cicloUpd(credentials, values)
            if (response.length === 0) {
                setSnackBarInfo({
                    message: "Ciclo lectivo modificado correctamente",
                    severity: "success",
                    open: true,
                });
                formikRef.current.resetForm();
                updateList();
                closeModal();
            }
        }
    };
    useEffect(() => {
        getCicloData()
    }, []);
    const dataFormik = {
        date: { label: "Fecha" },
    }
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                año: "",
                calendarioId: "",
                calendarioNombre: "",
                fechaDesde: "",
                fechaHasta: "",
                tramo: "",
                version: "",
                cicloId: ""
            }}
            validationSchema={
                yup.object({
                    año: yup.string("Debe ingresar un año").required("Debe ingresar un año"),
                    calendarioId: yup.string("Debe seleccionar un calendario").required("Debe seleccionar un calendario"),
                    fechaDesde: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                    fechaHasta: yup.string("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                    tramo: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre")
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="calendarioNombre"
                            labelText="Calendario"
                            disabled
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="tramo"
                            labelText="Tramo"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="año"
                            labelText="Año"
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fechaDesde"
                            labelText="Fecha Desde"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fechaDesde", e.target.value);
                            }}
                        />
                        <FormikDateInput
                            data={dataFormik}
                            formik={formikRef.current}
                            fullWidth={true}
                            name="fechaHasta"
                            labelText="Fecha hasta"
                            handleChange={(e) => {
                                formikRef.current.setFieldValue("fechaHasta", e.target.value);
                            }}
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
