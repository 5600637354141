import React, { useCallback, useEffect, useState } from "react";

import { CircularIndeterminate } from "components";
import { useParams } from "react-router-dom";

import { PersonalDataDashboard } from "./PersonalDataDashboard";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoilState/GlobalState";

import { RequestServiceGet } from "services/services-mia/RequestServiceGet";

import s from "styles/pages/Pages.module.scss"

export default function UserDetailsDashboard({
    usuarioId = null
}) {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const params = useParams()

    const getUser = useCallback(async (id) => {
        setIsLoading(true);

        const body = {
            service: "MIADemoUsuarioFichaGet",
            params: {
                usuarioId: id,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setUser(result[0]);

        setIsLoading(false);
    }, [credentials, setSnackbarInfo])

    useEffect(() => {
        getUser(usuarioId ?? params?.UsuarioId);
    }, [getUser, usuarioId, params?.UsuarioId]);

    if (isLoading || !user) {
        return (
            <main className={s.main}>
                <CircularIndeterminate />
            </main>
        );
    }

    return (
        <main className={s.main}>
            <div className={s.container}>
                <PersonalDataDashboard user={user} />
            </div>
        </main>
    );
}