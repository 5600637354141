import axiosPost from "../axiosPost";

export const loginQRMerchant = async ({
    codigoQR,
    merchantId,
}) => {
    const body = {
        service: "LoginQRMerchant",
        params: {
            codigoQR,
            merchantId,
        },
    };

    return await axiosPost(body);
};
