import axiosPost from "./axiosPost";

export const nivelModalidadDel = async (credentials, data) => {
    const body = {
        service: "NQNNivelModalidadDel",
        params: {
            nqnNivelModalidadId: data?.id,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
