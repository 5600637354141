import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { clienteUsuarioUpd } from "services/services-mia/clienteUsuarioUpd";
import { nivelModalidadGet } from "services/services-mia/nivelModalidadGet";
import Modal from "components/UI/Modal/Modal";
import InfoIcon from '@mui/icons-material/Info';

const status = [
    { statusCod: "A", status: "Activo" },
    { statusCod: "P", status: "Pendiente" },
    { statusCod: "B", status: "Baja" },
    { statusCod: "S", status: "Suspendido" },
    { statusCod: "X", status: "Cancelado" },
    { statusCod: "R", status: "Rechazado" },
];

const statusTransitions = {
    A: ["A", "B", "S"],
    P: ["P", "X"],
    B: ["B"],
    S: ["A", "S"],
    X: ["X", "P"],
    R: ["R", "B"],
};

const emailRegex =
    /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UpdateModal({ updateList, roles, user, closeModal, servicio }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const [statusAccepted, setStatusAccepted] = useState([]);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nivel, setNivel] = useState([]);
    const [editNivel, setEditNivel] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);

    const formikRef = useRef();

    const functions = {
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };
    const getData = async () => {
        const result = await nivelModalidadGet(
            credentials,
        );
        setNivel(result);
    }
    const handleUpdateUser = async (values) => {
        const { result, status } = await clienteUsuarioUpd(
            values,
            credentials,
        );
        if (result?.length === 0) {
            setSnackBarInfo({
                message: "Usuario modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    }
    const closeModalDetail = () => {
        setModalInfo(false);
    }
    useEffect(() => {
        formikRef.current.setFieldValue("estado", user.relacionStatusCod);
        formikRef.current.setFieldValue("rol", user.rolCod);
        formikRef.current.setFieldValue("userId", user.servicioUsuarioId);
        formikRef.current.setFieldValue("cel", user.telefono);
        formikRef.current.setFieldValue("mail", user.usuarioMail);
        formikRef.current.setFieldValue("name", user.usuarioNombres);
        formikRef.current.setFieldValue("lastname", user.usuarioApellidos);
        formikRef.current.setFieldValue("nivelId", user.nivelId);
        setStatusAccepted(status.filter((item) => statusTransitions[user.relacionStatusCod].includes(item.statusCod)));
        let rolType = roles.find((rol) => rol.rolCod === user.rolCod);
        if (rolType.nqnPermisoRolNivel === "NM") {
            setEditNivel(true);
        } else {
            setEditNivel(false);
        }
        getData()
    }, []);
    return (
        <>
            <Modal open={modalInfo} onClose={closeModalDetail}>
                <FormButton
                    onClick={closeModalDetail}
                    oneButton="true"
                    // inRequest={inRequest || isLoading}
                    newFormData={{ button: "Salir" }}
                    onCloseModal={closeModalDetail}
                />
            </Modal>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    userId: "",
                    estado: "",
                    mail: "",
                    rol: "",
                    cel: "",
                    name: "",
                    lastname: "",
                    nivelId: "",
                }}
                validationSchema={
                    yup.object({
                        estado: yup.string().required("El estado es requerido"),
                        rol: yup.string().required("El rol es requerido"),
                        mail: yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Debe ingresar un formato correcto"),
                        // cel: yup.string().matches(/^(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g, "Debe ingresar un formato correcto (10 dígitos)")
                    })
                }
                onSubmit={(values) => {
                    handleUpdateUser(values);
                }}
            >
                {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                    <div className={styles["column-sm"]}>
                        {<>
                            <FormikTextInput
                                fullWidth={true}
                                name="lastname"
                                labelText="Apellidos"
                                disabled={user.usuarioId}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="name"
                                labelText="Nombres"
                                disabled={user.usuarioId}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="mail"
                                labelText="Mail"
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="cel"
                                labelText="Celular"
                            />
                            <FormikSelectInput
                                fullWidth={true}
                                name="estado"
                                labelText="Estado"
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {statusAccepted?.map(({ statusCod, status }) => (
                                    <option
                                        key={statusCod}
                                        data-id={statusCod}
                                        value={statusCod}
                                    >
                                        {status}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", width: "100%" }}>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="rol"
                                    labelText="Rol"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("rol", e.target.value);
                                        let rolType = roles.find((rol) => rol.rolCod === e.target.value);
                                        if (rolType.nqnPermisoRolNivel === "NM") {
                                            setEditNivel(true);
                                        } else {
                                            setEditNivel(false);
                                            formikRef.current.setFieldValue("nivelId", "");
                                        }
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {roles.map(({ puedeCrear, rolCod, rolNombre }) => (
                                        puedeCrear === "Y" && <option
                                            key={rolCod}
                                            data-id={rolCod}
                                            value={rolCod}
                                        >
                                            {rolNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                                <div onClick={() => setModalInfo(true)} style={{ margin: "0 10px", cursor: "pointer" }}>
                                    <InfoIcon style={{ color: "#2B3E4C " }} />
                                </div>
                            </div>
                            <FormikSelectInput
                                fullWidth={true}
                                name="nivelId"
                                labelText="Nivel"
                                disabled={!editNivel}
                                style={{ visibility: editNivel ? "visible" : "hidden" }}
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {nivel.map(({ nqnNivelModalidadId, nqnNivelModalidadNombre }) => (
                                    <option
                                        key={nqnNivelModalidadId}
                                        data-id={nqnNivelModalidadId}
                                        value={nqnNivelModalidadId}
                                    >
                                        {nqnNivelModalidadNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </>}
                        <FormButton
                            inRequest={inRequest || isLoading}
                            newFormData={{ button: "Modificar" }}
                            onCloseModal={closeModal}
                        />
                    </div>
                </Form>}
            </Formik></>
    );
}
