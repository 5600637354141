import React, { act, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { nivelModalidadUpd } from "services/services-mia/nivelModalidadUpd";
import { nivelModalidadGet } from "services/services-mia/nivelModalidadGet";

const activeValues = [
    { value: "Y", name: "Si", id: "1" },
    { value: "N", name: "No", id: "2" },
];

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getNivelesData = async () => {
        setIsLoading(true)
        const result = await nivelModalidadGet(credentials, { id: user.id })
        formikRef.current.setValues({
            id: result[0].nqnNivelModalidadId,
            nombre: result[0].nqnNivelModalidadNombre,
            cod: result[0].nqnNivelModalidadCod,
            version: result[0].nqnNivelModalidadVersion,
            activo: result[0].nqnNivelModalidadActivo,
        });
        setData(result[0]);
        setIsLoading(false)
    }
    const handleSubmit = async (values) => {
        const response = await nivelModalidadUpd(credentials, values)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Nivel modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getNivelesData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                id: "",
                nombre: "",
                activo: "Y",
                cod: "",
                version: "",
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un código").max(10, "Debe tener menos de 10 caracteres").required("Debe ingresar un código"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Código"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="activo"
                            labelText="¿Es activo?"
                        // onChange={handleChangePais}
                        >
                            {activeValues.map(({ value, name, id }) => (
                                <option
                                    key={id}
                                    data-id={id}
                                    value={value}
                                >
                                    {name}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
