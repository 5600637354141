import axiosPost from "./axiosPost";

export const calendarioUpd = async (credentials, data) => {
    const body = {
        service: "NQNCalendarioUpd",
        params: {
            nqnCalendarioId: data.id,
            nqnCalendarioNombre: data.nombre,
            nqnCalendarioCod: data.cod,
            nqnCalendarioVersion: data.version,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
