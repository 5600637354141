import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import moment from "moment";

// utils
import { InsertModal, UpdateModal, ExportModal, DetailModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";
import { Button, Buttons, HeaderButton } from "components";
// consts
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
//Assets
import { Add, Cached, EditOutlined, CloudDownload, VisibilityOutlined } from '@mui/icons-material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, userCredentials, userServices } from "recoilState/GlobalState";
import { clienteUsuarioGet } from "services/services-mia";

import Filters from "components/Filters";
import * as yup from "yup";
import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { rolesGet } from "services/services-mia/rolesGet";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

export default function UsuariosXServicio() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [roles, setRoles] = useState([]);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceReset, setForceReset] = useState(false);
    const [forceUpload, setForceUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const setRoute = useSetRecoilState(routeInfo);
    const credentials = useRecoilValue(userCredentials);
    const permisos = useRecoilValue(userServices);
    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const validationSchema = yup.object({
        dni: yup.string().max(10, "Debe tener menos de 10 caracteres"),
    });

    const formik = useFormik({
        initialValues: {
            apellido: "",
            dni: "",
            rol: "",
            fechaDesde: "",
            fechaHasta: "",
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            setForceReset(!forceReset)
            setForceUpload(!forceUpload);
            setRowSelected({ status: false, row: {} });
            updateList()
        },
    });

    const getUsers = async (page, pageSize) => {
        setIsLoading(true);
        const data = {
            rolCod: formik.values.rol || null,
            page: page + 1,
            pageSize,
            usuarioTieneApp: null,
            usuarioNroDeDocumentoX: formik.values.dni || null,
            usuarioApellidos: formik.values.apellido || null,
            fechaDesde: formik.values.fechaDesde ? moment(formik.values.fechaDesde).format("YYYY-MM-DD") : null,
            fechaHasta: formik.values.fechaHasta ? moment(formik.values.fechaHasta).format("YYYY-MM-DD") : null,
            merchantServicioUsuarioStatus: formik.values.merchantServicioUsuarioStatus || null,

        }
        const rol = await rolesGet(credentials);
        setRoles([{ rolCod: "", rolNombre: "Todos" }, ...rol]);
        const { result, total } = await clienteUsuarioGet(data, credentials);
        const newRows = result ? result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                servicioUsuarioId: usuario.merchantServicioUsuarioId,
                relacionStatus: usuario.relacionStatus,
                relacionStatusCod: usuario.relacionStatusCod,
                rolCod: usuario.rolCod,
                rolId: usuario.rolId,
                rolNombre: usuario.rolNombre,
                servicioCod: usuario.servicioCod,
                servicioId: usuario.servicioId,
                servicioNombre: usuario.servicioNombre,
                tipoDeDocumentoCod: usuario.tipoDeDocumentoCod,
                tipoDeDocumentoNombre: usuario.tipoDeDocumentoNombre,
                usuarioAPPVersion: usuario.usuarioAPPVersion,
                usuarioApellidos: usuario.usuarioApellidos,
                usuarioFecha: usuario.usuarioFecha,
                usuarioFechaDeActivo: usuario.usuarioFecha,
                usuarioFechaDeAlta: usuario.usuarioFechaDeAlta,
                usuarioFechaDeNacimiento: usuario.usuarioFechaDeNacimiento,
                usuarioId: usuario.usuarioId,
                usuarioMail: usuario.usuarioMail,
                usuarioNombreCompleto: `${usuario.usuarioApellidos || ""}${usuario.usuarioApellidos === null || usuario.usuarioNombres === null ? "" : ","} ${usuario.usuarioNombres || ""}`,
                usuarioNombres: usuario.usuarioNombres,
                usuarioNroDeDocumento: usuario.usuarioNroDeDocumento,
                usuarioNroDeDocumentoX: usuario.usuarioNroDeDocumentoX,
                usuarioSexo: usuario.usuarioSexo === "M" ? "Masculino" : usuario.usuarioSexo === "F" ? "Femenino" : "No binario",
                onboard: usuario.usuarioId === null ? 'NO' : 'SI',
                telefono: usuario.usuarioTelefonoCelular,
                nivelId: usuario.nqnNivelModalidadId,
                nivelNombre: usuario.nqnNivelModalidadNombre
            };
        }) : [];
        setIsLoading(false);
        return {
            rows: newRows,
            rowCount: total,
        };
    };
    useEffect(() => {
        getUsers()
    }, [])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const resetTable = () => {
        setForceReset(!forceReset)
        setForceUpload(!forceUpload);
    }
    const resetForm = () => {
        formik.setFieldValue("dni", "")
        formik.setFieldValue("apellido", "")
        formik.setFieldValue("rol", "")
        formik.setFieldValue("fechaDesde", "")
        formik.setFieldValue("fechaHasta", "")
        setForceReset(!forceReset)
        setForceUpload(!forceUpload);
        setRowSelected({ status: false, row: {} });
        updateList()
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={roles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.EXPORT && (
                    <ExportModal
                        user={rowSelected.row}
                        // servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        // servicio={service}
                        roles={roles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        // servicio={service}
                        userData={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "text", name: "apellido", label: "Apellido", disabled: false },
                                { type: "text", name: "dni", label: "DNI" },
                                { type: "select", name: "rol", label: "Rol", options: roles, firtsOptionKey: "rolCod", firstOptionName: "rolNombre", firstOptionValue: "rolCod" },
                                { type: "date", name: "fechaDesde", label: "Fecha desde", disabled: false },
                                { type: "date", name: "fechaHasta", label: "Fecha hasta", disabled: false },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <HeaderButton text={"Borrar filtros"} onClick={() => resetForm()} disabled={isLoading} ><FilterAltOffRoundedIcon /></HeaderButton>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { resetTable() }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        disabled={!rowSelected.row.id || permisos.usuarios === "N" || rowSelected.row.onboard === "NO"}
                        data={{
                            title: "Detalle",
                            procede: ACTIONS.DETAIL
                        }}
                    >
                        <VisibilityOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear conexión"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear conexión con usuario",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || permisos.usuarios !== 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Modificar relación"}
                        handleClick={handleClick}
                        data={{
                            title: "Actualizar conexión",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || permisos.usuarios !== 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton>

                    <HeaderButton
                        text={"Descargar Excel"}
                        handleClick={handleClick}
                        data={{
                            title: "Descargar archivo Excel",
                            procede: ACTIONS.EXPORT
                        }}
                        disabled={rowSelected.row.id || permisos.usuarios === 'N'}
                    >
                        <CloudDownload />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getUsers}
                    forceUpload={forceUpload}
                    forceReset={forceReset}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
