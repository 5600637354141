import axiosPost from "./axiosPost";

export const rolesGet = async (credentials, data) => {
    const body = {
        service: "NQNRolGet",
        params: {
        },
    };
    const result = await axiosPost(body, credentials);
    if(result.status.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
