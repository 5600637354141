import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { getFeriados } from "services/services-mia";
import { serieFeriadoDel } from "services/services-mia/serieFeriadoDel";
import { getSerieFeriado } from "services/services-mia/serieFeriadoGet";
import { cicloGet } from "services/services-mia/cicloGet";
import { cicloDel } from "services/services-mia/cicloDel";

export default function DeleteModal({ user, updateList, servicio, closeModal, setterRowSelected }) {
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState([
        { value: "N", name: "Nacional", id: "1" },
        { value: "P", name: "Provincial", id: "2" },
        { value: "J", name: "Jornada Pedagogica", id: "3" }
    ]);
    const [data, setData] = useState();

    const formikRef = useRef();

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getCicloData = async () => {
        setIsLoading(true);
        const result = await cicloGet(credentials, { cicloId: user.id })
        formikRef.current.setValues({
            calendario: result[0].nqnCalendarioNombre,
            tramo: result[0].nqnCalendarioCicloTramo,
        });
        setData(result[0]);
        setIsLoading(false);
    }

    const handleDeleteCiclo = async (values) => {
        const response = await cicloDel(
            credentials,
            { id: data.nqnCalendarioCicloId }
        );
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Ciclo lectivo eliminado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            setterRowSelected({ status: false, row: {} })
            closeModal();
        }
    }

    useEffect(() => {
        getCicloData()
    }, []);
    console.log(data, "data")
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                calendario: "",
                tramo: "",
            }}
            validationSchema={
                yup.object({
                    calendario: yup.string("Debe ingresar un nombre").required("Debe ingresar un nombre"),
                    tramo: yup.string("Debe seleccionar un tramo").required("Debe seleccionar un tramo"),
                })
            }
            onSubmit={(values) => {
                handleDeleteCiclo(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="calendario"
                            labelText="Calendario"
                            disabled
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="tramo"
                            labelText="Tramo"
                            disabled
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
