import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from "styles/pages/Pages.module.scss"
import { RNCAResumenGet } from 'services/services-mia/RNCAResumenGet';
import XGrid from 'components/UI/Grid/XGrid';
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { resumenDisponibles as titlesDisponibles, resumenTransferencias as titlesTransferencias, resumenRetiro as titlesRetiros } from 'consts/titulos-de-tablas';
import { v4 as uuidV4 } from "uuid";
import { Grid } from '@mui/material';
export default function BasicTabs({ user }) {
    const [value, setValue] = useState(0);
    const credentials = useRecoilValue(userCredentials);
    const [disponibles, setDisponibles] = useState([]);
    const [transferencias, setTransferencias] = useState([]);
    const [retiros, setRetiros] = useState([]);
    useEffect(() => {
        getResumen();
    }, [])

    const getResumen = async () => {
        const response = await RNCAResumenGet(user.usuarioId, credentials);
        const responseFilterDisponibles = response.result.filter((e) => e.movimientoStatus === "D")
        const responseFilterTransferencias = response.result.filter((e) => e.movimientoStatus === "T")
        const responseFilterRetiros = response.result.filter((e) => e.movimientoStatus === "R")
        const mapDisponibles = responseFilterDisponibles.map((e) => {
            return {
                id: uuidV4(),
                [titlesDisponibles[0]]: e.movimientoCantidad,
                [titlesDisponibles[1]]: e.movimientoDisponible === "Y" ? "Si" : "No",
                [titlesDisponibles[2]]: `${e.movimientoContraparteNombre} ${e.movimientoContraparteDNI ? e.movimientoContraparteDNI : ""}`,
                [titlesDisponibles[3]]: e.movimientoStatusFecha
            }
        })
        const mapTransferencia = responseFilterTransferencias.map((e) => {
            return {
                id: uuidV4(),
                [titlesTransferencias[0]]: e.movimientoCantidad,
                [titlesTransferencias[1]]: e.movimientoDisponible === "Y" ? "Si" : "No",
                [titlesTransferencias[2]]: `${e.movimientoContraparteNombre} ${e.movimientoContraparteDNI ? e.movimientoContraparteDNI : ""}`,
                [titlesTransferencias[3]]: e.movimientoStatusFecha
            }
        })
        const mapRetiros = responseFilterRetiros.map((e) => {
            return {
                id: uuidV4(),
                [titlesRetiros[0]]: e.movimientoCantidad,
                [titlesRetiros[1]]: e.movimientoDisponible === "Y" ? "Si" : "No",
                [titlesRetiros[2]]: `${e.movimientoContraparteNombre} ${e.movimientoContraparteDNI ? e.movimientoContraparteDNI : ""}`,
                [titlesRetiros[3]]: e.movimientoStatusFecha
            }
        });
        setDisponibles(mapDisponibles);
        setTransferencias(mapTransferencia);
        setRetiros(mapRetiros);
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <main className={styles.main}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} width={"100%"}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered variant="fullWidth" >
                    <Tab label="Disponibles" {...a11yProps(0)} />
                    <Tab label="Tranfencias" {...a11yProps(1)} />
                    <Tab label="Retiros" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
                <XGrid rows={disponibles} titles={titlesDisponibles} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} >
                <XGrid rows={transferencias} titles={titlesTransferencias} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} >
                <XGrid rows={retiros} titles={titlesRetiros} />
            </CustomTabPanel>


        </main>
    );
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ display: value !== index ? "none" : "flex" }}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}

            {...other}
        >
            <Grid container display={"flex"} justifyContent={"flex-start"}>
                <Grid item md="12" height={"60vh"} mt={4} >
                    {value === index && <>{children} </>}
                </Grid>

            </Grid>



        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}