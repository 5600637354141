import React, { useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput, FormikDateInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";
import moment from 'moment';

import { useRecoilValue } from "recoil";
import {
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { escuelasGet } from "services/services-mia/escuelasGet";
import { Box, Grid } from "@mui/material";
import { escuelasStatus } from "services/services-mia/escuelaStatus";

const type = [
    { value: "A", name: "Activa", id: "1" },
    { value: "C", name: "Cerrada", id: "2" },
    { value: "T", name: "Cerrada temporalmente", id: "3" }
];

export default function StatusModal({ updateList, user, servicio, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getData = async () => {
        setIsLoading(true);
        const result = await escuelasGet(credentials, { id: user.id })
        formikRef.current.setValues({
            escuelaId: result[0].nqnEscuelaId,
            status: result[0].nqnEscuelaStatusCod,
            motivo: result[0].nqnEscuelaStatusMotivo,
            statusFecha: moment(new Date()).format('YYYY-MM-DD'),
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await escuelasStatus(credentials, values)
        if (response.length === 0) {
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getData()
    }, []);
    const dataFormik = {
        statusFecha: { label: "Fecha" },
    }
    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    escuelaId: "",
                    status: "",
                    motivo: "",
                    statusFecha: "",
                }}
                validationSchema={
                    yup.object({
                        status: yup.string("Debe seleccionar un estado").typeError("Debe seleccionar un estado").required("Debe seleccionar un estado"),
                        motivo: yup.string("Debe ingresar un motivo").max(500, "Debe tener menos de 500 caracteres").typeError("Debe ingresar un motivo").required("Debe ingresar un motivo"),
                        statusFecha: yup.date("Debe ingresar una fecha").typeError("Debe ingresar una fecha").required("Debe ingresar una fecha"),
                    })
                }
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                    <div className={styles["column-sm"]}>
                        {step.basico && <Grid width={"100%"}>
                            <Box display={"flex"} width={"100%"} gap={4}>
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="status"
                                    labelText="Status"
                                    onChange={(e) => {
                                        formikRef.current.setFieldValue("status", e.target.value);
                                    }}
                                >
                                    <option value={""} selected disabled >
                                        Seleccione
                                    </option>
                                    {type.map(({ value, name, id }) => (
                                        <option
                                            key={id}
                                            data-id={value}
                                            value={value}
                                        >
                                            {name}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Box>
                            <Box display={"flex"} width={"100%"} gap={4}>
                                <FormikTextInput
                                    fullWidth={true}
                                    name="motivo"
                                    labelText="Motivo"
                                />
                            </Box>
                            <Box display={"flex"} width={"100%"} gap={4}>
                                <FormikDateInput
                                    data={dataFormik}
                                    formik={formikRef?.current}
                                    fullWidth={true}
                                    name="statusFecha"
                                    labelText="Fecha"
                                    handleChange={(e) => {
                                        formikRef.current.setFieldValue("statusFecha", e.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>}
                        <FormButton
                            // inRequest={inRequest || isLoading}
                            newFormData={{ button: "Aplicar" }}
                            onCloseModal={closeModal}
                        />

                        {(inRequest || isLoading) && <CircularIndeterminate />}
                    </div>
                </Form>}
            </Formik>
        </>
    );
}
