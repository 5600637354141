import axiosPost from "./axiosPost";

export const RNESArticuloGet = async (credentials) => {
    const body = {
        service: "RNESArticulosGet",
        params: {},
    };

    const result = await axiosPost(body, credentials);

    return result.result;
};
