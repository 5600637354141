import axiosPost from "./axiosPost";

export const usuarioMinimosGet = async (credentials) => {
    const body = {
        service: "UsuarioMinimosGet",
        params: {},
    };

    const result = await axiosPost(body, credentials);
    return result.result[0];
};
