import axiosPost from "./axiosPost";

export const RNConnectionIns = async (data, credentials) => {
    const {
        servicioHooliCod,
        rolCod,
        usuarioNroDeDocumentoX,
        usuarioSexo,
        usuarioMail,
        usuarioCelular,
    } = data;
    const body = {
        service: "RNConnectionIns",
        params: {
            servicioHooliCod,
            rolCod,
            usuarioCelular,
            usuarioNroDeDocumentoX,

            usuarioMail,
            usuarioSexo,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
