import axiosPost from "./axiosPost";

export const clienteUsuarioIns = async ({
    dni,
    sexo = null,
    rol=null,
    mail = null,
    cel = null,
    name = null,
    lastname = null,
    nivelId = null,
}, credentials) => {
    const body = {
        service: "NQNConnectionIns",
        params: {
            usuarioNroDeDocumentoX: dni,
            usuarioSexo: sexo,
            rolCod: rol,
            nqnNivelModalidadId: nivelId,
            usuarioApellidos: lastname,
            usuarioNombres: name,
            usuarioMail: mail,
            usuarioCelular: cel,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return {result: result.result,
                total: result.status.id
        };
    }
};
