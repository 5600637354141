import React, { useEffect, useRef, useState, version } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { getFeriados } from "services/services-mia";
import { feriadoUpd } from "services/services-mia/feriadoUpd";
import { calendarioGet } from "services/services-mia/calendarioGet";
import { calendarioUpd } from "services/services-mia/calendarioUpd";

export default function UpdateModal({ updateList, roles, user, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [type, setType] = useState([
        { value: "N", name: "Nacional", id: "1" },
        { value: "P", name: "Provincial", id: "2" },
        { value: "J", name: "Jornada Pedagogica", id: "3" }
    ]);
    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getCalendarioData = async () => {
        setIsLoading(true);
        const result = await calendarioGet(credentials, { id: user.id })
        formikRef.current.setValues({
            id: result[0].nqnCalendarioId,
            nombre: result[0].nqnCalendarioNombre,
            cod: result[0].nqnCalendarioCod,
            version: result[0].nqnCalendarioVersion,
        });
        setData(result[0]);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await calendarioUpd(credentials, values)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Calendario modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getCalendarioData()
    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                id: "",
                nombre: "",
                cod: "",
                version: ""
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string("Debe ingresar un nombre").max(45, "Debe tener menos de 45 caracteres").required("Debe ingresar un nombre"),
                    cod: yup.string("Debe ingresar un codigo").max(15, "Debe tener menos de 15 caracteres").required("Debe ingresar un codigo"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="cod"
                            labelText="Codigo"
                        />
                    </>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
