import axiosPost from "./axiosPost";

export const calendarioIns = async (credentials, data) => {
    const body = {
        service: "NQNCalendarioIns",
        params: {
            nqnCalendarioCod: data?.cod,
            nqnCalendarioNombre: data?.nombre
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
